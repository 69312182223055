import { memo, useEffect, useState } from "react";
import { Autocomplete, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { TEMPLATE_CATEGORIES } from "./values/TemplateCategories";
import { useSVG } from "./SVGContext";
import SlidingNav from "./SlidingNav";
import LazyImage from "./library/LazyImage";
import { getTemplatePreviewUrl } from "./utils/utils";
import { SUBCATEGORIES } from "./values/Subcategories";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import { LazyImageWithAddBtn } from "./library/LazyImageWithAddBtn";
import { BottomBar } from "./BottomBar";
import { Banner } from "./Banner";
import { useNavigate } from "react-router-dom";

const StackedTemplateCategories = memo(() => {
  const { userInfo, designs, onTemplateSelect, setFullscreenLoading } = useSVG();

  return (
    <div>
      <Banner />
      <div
        style={{
          padding: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "start",
        }}
      >
        <Searchbox />

        {designs && designs.length > 0 && (
          <SlidingNavElement
            title={"Recent Designs"}
            objects={designs}
            getPreview={(design) => design.preview}
            getTitle={(design) => design.title}
            onClick={(design) => {
              setFullscreenLoading(true);
              const url = "/design/" + design.id;
              window.open(url, "_blank");
            }}
            seeAllLink={"/designs"}
            addString={"Edit Your Design"}
          />
        )}

        {Object.keys(SUBCATEGORIES).map((cat, i) => (
          <SlidingNavElement
            key={i}
            title={cat}
            objects={getTemplateIds(cat)} // Just to get the ids
            getTitle={(id) => id}
            getPreview={(id) => getTemplatePreview(id)}
            onClick={(id) => {
              onTemplateSelect(id);
            }}
            seeAllLink={`/templates/${cat}`}
            addString={"Create Design"}
          />
        ))}
      </div>
      <BottomBar />
    </div>
  );
});

function getTemplateIds(cat) {
  const t = SUBCATEGORIES[cat];
  let res = [];
  for (let k in t) {
    res = [...res, ...t[k]];
    if (res.length >= 6) break;
  }
  return res;
}
function getTemplatePreview(id) {
  return getTemplatePreviewUrl(id);
}

export default StackedTemplateCategories;

function SlidingNavElement({ title, objects, getPreview, getTitle, onClick, seeAllLink, addString }) {
  return (
    <div>
      <div
        style={{
          marginTop: 25,
          marginBottom: 5,
          marginLeft: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong
          style={{
            fontSize: "1.2rem",
          }}
        >
          {title[0].toUpperCase() + title.slice(1)}
        </strong>
        <span
          className="see-all"
          onClick={() => {
            window.open(seeAllLink, "_blank");
          }}
        >
          See all
        </span>
      </div>

      <SlidingNav>
        {objects.map((obj, i) => (
          <LazyImageWithAddBtn key={i} src={getPreview(obj)} alt={getTitle(obj)} height={200} onClick={() => onClick(obj)} addString={addString} />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <IconButton
            variant="outlined"
            style={{
              width: 50,
              height: 50,
              border: "1px solid #e0e0e0",
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </SlidingNav>
    </div>
  );
}

function Searchbox({}) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleOptionSelect = (event, value) => {
    if (value) {
      navigate(`/templates?q=${encodeURIComponent(query)}`);
    }
  };

  return (
    <Autocomplete
      freeSolo
      onChange={handleOptionSelect}
      options={query ? [`Search templates for "${query}"`] : []}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder="Search thousands of templates"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 20, width: "100%" }}
          onChange={(e) => setQuery(e.target.value)}
        />
      )}
    />
  );
}
