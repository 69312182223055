import { deleteImage, fetchImages, searchIcons, searchPexelsImages, uploadImage } from "../utils/ServerUtils";
import { useSVG } from "../SVGContext";
import "./ImagesPanel.css";
import { Dialog, DialogContent, DialogTitle, IconButton, ImageListItem, TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddTemplateBtn } from "../AddtemplateBtn";
import Masonry from "@mui/lab/Masonry";

const selectedTabBackground = "black";
const selectedTabText = "white";

export function ImageUploads({ images, reload }) {
  const { addImage, setFullscreenLoading } = useSVG();

  return (
    <div
      style={{
        background: selectedTabBackground,
        color: selectedTabText,
        flexGrow: 1,
        padding: 10,

        display: "flex",
        flexDirection: "column",
        gap: 10,
        justifyContent: "start",
        alignItems: "stretch",
      }}
    >
      <Upload reload={reload} setFullscreenLoading={setFullscreenLoading} />

      <Masonry
        columns={2}
        style={{
          flexGrow: 1,
        }}
      >
        {images &&
          images.map((image) => (
            <ImageListItem
              key={image.id}
              className="image-container image-delete-btn-wrapper"
              style={{
                cursor: "pointer",
              }}
            >
              <img src={image.src} alt={image.name} onClick={() => addImage(image)} />
              <div className="options"></div>
              <DeleteBtn image={image} reload={reload} />
            </ImageListItem>
          ))}
      </Masonry>
    </div>
  );
}
function DeleteBtn({ image, reload }) {
  return (
    <IconButton
      size="small"
      onClick={() => {
        deleteImage(image.id, reload);
      }}
      className="image-delete-btn"
      style={{
        color: "#9f0303",
        position: "absolute",
        top: 0,
        right: 0,
        display: "none",
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
}
function Upload({ reload, setFullscreenLoading }) {
  const [showUrlDialog, setShowUrlDialog] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const { addImage } = useSVG();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-equally",
          alignItems: "center",
          marginBottom: 10,
          gap: 10,
        }}
      >
        <Button
          type="contained"
          onClick={() => {
            inputRef.current.click();
          }}
          style={{
            width: "100%",
            background: "purple",
            textTransform: "none",
          }}
        >
          + Upload Image
        </Button>
        <Button
          type="outlined"
          onClick={() => {
            setShowUrlDialog(true);
          }}
          style={{
            width: "100%",
            background: "purple",
            textTransform: "none",
          }}
        >
          + Add from URL
        </Button>
      </div>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          setFullscreenLoading(true);
          uploadImageWithDimensions(
            e.target.files[0],
            () => {
              reload();
              // clear the input
              e.target.value = "";
              setFullscreenLoading(false);
            },
            function (err) {
              console.error(err);
              e.target.value = "";
              setFullscreenLoading(false);
              setError(err);
              setTimeout(() => {
                setError(null);
              }, 10000);
            },
          );
        }}
      />
      <div
        style={{
          color: "red",
        }}
      >
        {error}
      </div>

      <Dialog
        open={showUrlDialog}
        onClose={() => setShowUrlDialog(false)}
        style={{
          padding: 20,
        }}
      >
        <DialogTitle>Add Image from URL</DialogTitle>
        <DialogContent
          style={{
            // height: 300,
            // background: "white",
            padding: 30,

            display: "flex",
            flexDirection: "column",
            gap: 20,
            justifyContent: "start",
            alignItems: "stretch",
            width: 500,
          }}
        >
          <TextField type="url" label="Image URL" fullWidth />
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              const url = document.querySelector("input[type=url]").value;
              const img = new Image();
              img.onload = () => {
                addImage({
                  width: img.width,
                  height: img.height,
                  src: url,
                });
              };
              img.src = url;
            }}
            style={{
              background: "purple",
              color: "white",
            }}
          >
            Add Image
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const uploadImageWithDimensions = (file, cb, error) => {
  if (!file.type.startsWith("image/")) {
    error("File is not an image.");
    return;
  }

  const reader = new FileReader();
  reader.onload = (event) => {
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", width);
      formData.append("height", height);

      uploadImage(formData)
        .then(cb)
        .catch((e) => error("Error uploading image.", e));
    };
    img.src = event.target.result;
  };

  reader.readAsDataURL(file);
};
