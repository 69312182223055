import { Button, ButtonGroup, Divider, IconButton, InputLabel, ListSubheader, Menu, MenuItem, Select, Slider, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSVG } from "../SVGContext";
import { GoogleFontFamilies } from "../values/GoogleFontFamilies";
import React, { useEffect, useState } from "react";
import { allGoogleFonts } from "../values/all-google-fonts";
import AlignLeft from "@mui/icons-material/FormatAlignLeft";
import AlignCenter from "@mui/icons-material/FormatAlignCenter";
import AlignRight from "@mui/icons-material/FormatAlignRight";
import { ColorSquare } from "../ColorSquare";
import { addFontDef } from "../utils/utils";
import { RotateBtn } from "./RotateBtn";
import { FontsList } from "../FontsList";
import { TopbarCommons, TopBarNumber } from "./TopbarCommons";
import MyIconButton from "./MyIconButton";
import MyButton from "./MyButton";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import { updateBoldItalicUnderline, updateColor, updateTextAlign, updateTextSize } from "../utils/proseMirrorUtils";
import { generateHTML } from "@tiptap/core";
import { EditorExtensions } from "../views/Tiptap";

const TopbarText = ({ item }) => {
  let { editor, design } = useSVG();
  if (!item) return;

  function onLineHeightChange(e) {
    editor.chain().focus().setLineHeight(e.target.value).run();
    editor.view.dispatch(editor.state.tr);
  }

  return (
    <>
      <FontColor item={item} />
      <FontsList />
      <FontSize item={item} />
      <TopBarNumber name="Line Height" valueName="line-height" min={0} max={5} step={0.1} defaultValue={1} icon={<FormatLineSpacingIcon />} onChange={onLineHeightChange} />

      <BoldItalicUnderline item={item} />
      <Align item={item} />
    </>
  );
};

export default TopbarText;

export function updateAllSelectedTexts(items, selectedIds, fn, val, update) {
  for (let id in selectedIds) {
    const item = items[selectedIds[id]];
    if (item.proseMirrorData) {
      item.proseMirrorData = fn(item.proseMirrorData, val);
      item.html = generateHTML(item.proseMirrorData, EditorExtensions);
      items[selectedIds[id]] = item;
    }
  }
  update({ items });
}

function FontSize() {
  const { editor, editorState, selectedIds, items, update } = useSVG();
  const [showTextField, setShowTextField] = useState(false);
  const [fontSizeNum, setFontSizeNum] = useState(12);

  useEffect(() => {
    if (editor.isFocused) {
      setFontSizeNum(editorState.fontSize.replace ? +editorState.fontSize.replace("px", "") : +editorState.fontSize);
    } else if (selectedIds.length > 1) {
      setFontSizeNum(" ");
    }
  }, [selectedIds, editorState, items]);

  function setFontSize(size, sign) {
    if (!editor.isFocused) {
      updateAllSelectedTexts(items, selectedIds, recursivelyUpdateFontSize, sign, update);
      return;
    }

    editor
      .chain()
      .focus()
      .setFontSize(size + "px")
      .run();
    editor.view.dispatch(editor.state.tr);
  }

  return (
    <>
      {!showTextField && (
        <ButtonGroup>
          <Button onClick={() => setFontSize(fontSizeNum - 1, -1)}>
            <span>-</span>
          </Button>
          <Button onClick={() => setShowTextField(true)}>{fontSizeNum}</Button>
          <Button onClick={() => setFontSize(fontSizeNum + 1, 1)}>
            <span>+</span>
          </Button>
        </ButtonGroup>
      )}
      <div
        style={{
          display: showTextField ? "flex" : "none",
          // width:0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <TextField
          label="Size"
          type="number"
          size="small"
          value={fontSizeNum}
          onChange={(e) => setFontSize(+e.target.value || 1)}
          onBlur={(e) => {
            setFontSize(+e.target.value || 1);
            setShowTextField(false);
          }}
          style={{ display: showTextField ? "block" : "none", width: 60 }}
        />
      </div>
    </>
  );
}

function Align({ item }) {
  const { editor, editorState, items, selectedIds, update } = useSVG();

  const [anchorEl, setAnchor] = useState(null);

  let alignment = editorState["text-align"];

  return (
    <div>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        {(!alignment || alignment === "left") && <AlignLeft />}
        {alignment === "center" && <AlignCenter />}
        {alignment === "right" && <AlignRight />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {["Left", "Center", "Right"].map((choice, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              if (selectedIds.length > 1) {
                updateAllSelectedTexts(items, selectedIds, updateTextAlign, choice.toLowerCase(), update);
                return;
              }
              editor.chain().focus().setTextAlign(choice.toLowerCase()).run();
              editor.view.dispatch(editor.state.tr);

              setAnchor(null);
            }}
          >
            {choice === "Left" && <AlignLeft />}
            {choice === "Center" && <AlignCenter />}
            {choice === "Right" && <AlignRight />}
            <span style={{ marginLeft: 8 }}>{choice}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const editorValue = {
  B: "Bold",
  I: "Italic",
  U: "Underline",
};

function BoldItalicUnderline({ item }) {
  const { editor, items, selectedIds, update } = useSVG();
  const [isPressed, setIsPressed] = useState({});

  useEffect(() => {
    if (selectedIds.length > 1 && items[selectedIds[0]].proseMirrorData) {
      setIsPressed({
        bold: false,
        italic: false,
        underline: false,
      });
    } else {
      setIsPressed({
        bold: editor.isActive("bold"),
        italic: editor.isActive("italic"),
        underline: editor.isActive("underline"),
      });
    }
  }, [selectedIds]);

  return (
    <ButtonGroup>
      {["B", "I", "U"].map((t, i) => (
        <MyButton
          key={i}
          // size="small"
          isPressed={isPressed[t.toLowerCase()]}
          onClick={() => {
            if (selectedIds.length > 1) {
              const val = isPressed[t.toLowerCase()] ? false : true;
              updateAllSelectedTexts(
                items,
                selectedIds,
                updateBoldItalicUnderline,
                {
                  [editorValue[t].toLowerCase()]: val,
                },
                update,
              );
              setIsPressed({
                ...isPressed,
                [t.toLowerCase()]: val,
              });
              return;
            }
            const fn = "toggle" + editorValue[t];
            editor.chain().focus()[fn]().run();
          }}
        >
          <span
            style={{
              textDecoration: t,
            }}
          >
            {t}
          </span>
        </MyButton>
      ))}
    </ButtonGroup>
  );
}

function FontColor() {
  const { editor, editorState, items, selectedIds, update } = useSVG();

  return (
    <ColorSquare
      color={editorState.color}
      onChange={(color) => {
        if (selectedIds.length > 1) {
          updateAllSelectedTexts(items, selectedIds, updateColor, color.hex, update);
          return;
        }
        editor.chain().focus().setColor(color.hex).run();
        editor.view.dispatch(editor.state.tr);
      }}
    />
  );
}

function recursivelyUpdateFontSize(node, sign) {
  if (node.attrs?.fontSize) {
    node.attrs.fontSize = addToFontSize(node.attrs.fontSize, sign);
  }
  if (node.marks) {
    for (let mark of node.marks) {
      if (mark.attrs?.fontSize) mark.attrs.fontSize = addToFontSize(mark.attrs.fontSize, sign);
    }
  }

  node.content?.forEach((child) => {
    recursivelyUpdateFontSize(child, sign);
  });

  return node;
}

function addToFontSize(fontSizeStr, num) {
  let px = "";
  if (fontSizeStr.includes("px")) {
    px = "px";
  }
  if (fontSizeStr.includes("pt")) {
    px = "pt";
  }
  return +fontSizeStr.replace(px, "") + num + px;
}
