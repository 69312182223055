import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { GRADIENTS } from "../values/Gradients";
import MySketchPicker, { customSwatches } from "../MySketchPicker";
import { Dialog, Slider, TextField, Tooltip } from "@mui/material";
import { DEFTYPES } from "../values/enums";
import { BGDEF, ROOT_GROUP_ID } from "../values/constants";
import AddIcon from "@mui/icons-material/Add";

export const BACKGROUND_DEF_SUFFIX = "background";

export function BackgroundColorPanel({}) {
  const { items, defs, update, width, height, groups, selectedIds } = useSVG();
  const [itemId, setItemId] = useState(selectedIds[0] || "background");

  useEffect(() => {
    setItemId(selectedIds[0] || "background");
  }, [selectedIds]);

  function onClick(colors) {
    const item = items[itemId];
    const defId = item.id + "-" + BACKGROUND_DEF_SUFFIX;
    let def = item.defs?.find((def) => def.id === defId);
    if (!def) {
      def = {
        id: defId,
        type: DEFTYPES.LINEARGRADIENT,
        colors: [],
        gradientUnits: "objectBoundingBox",
        x1: "0%",
        y1: "50%",
        x2: "100%",
        y2: "50%",
      };
      item.defs = item.defs || [];
      item.defs.push(def);
    } else {
      def.x1 = "0%";
      def.y1 = "50%";
      def.x2 = "100%";
      def.y2 = "50%";
      def.colors = [...colors];
    }
    item.fill = 'url("#' + defId + '")';
    update({ items, defs, groups });

    // copy the color to clipboard
    navigator.clipboard.writeText(colors[0]);
  }

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <PageColors onClick={onClick} />
      <Solids onClick={onClick} />
      <Gradients onClick={onClick} />
    </div>
  );
}

const subtitle = {
  fontSize: "0.8rem",
  fontWeight: "bold",
  margin: "10px 0",
};

const colorsHolder = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "start",
  alignItems: "start",
  gap: 15,
};

function PageColors({ onClick }) {
  const { design, selectedPage } = useSVG();
  return (
    <>
      <div style={subtitle}>Page Colors</div>
      <div style={colorsHolder}>
        {design.pages[selectedPage]?.colors.map((color, index) => (
          <Circle key={index} background={color} onClick={() => onClick([color])} name={color} />
        ))}
      </div>
    </>
  );
}

function Solids({ onClick }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const solids = customSwatches;
  return (
    <>
      <div style={subtitle}>Solids</div>
      <div style={colorsHolder}>
        {solids.map((color, index) => (
          <Circle key={index} background={color} onClick={() => onClick([color])} name={color} />
        ))}
        <Circle
          background={"white"}
          onClick={() => {
            setShowColorPicker(true);
          }}
          name={"New"}
          icon={<AddIcon style={{ color: "#333" }} />}
        />
      </div>
      <Dialog open={showColorPicker} onClose={() => setShowColorPicker(false)}>
        <MySketchPicker
          colors={"red"}
          onChangeComplete={(clr) => {
            onClick([clr.hex]);
            setShowColorPicker(false);
          }}
          addClickAway={false}
          setShowPicker={setShowColorPicker}
        />
      </Dialog>
    </>
  );
}

function computeGradientLine(angleDeg) {
  const angle = (angleDeg * Math.PI) / 180;
  const x1 = 0;
  const y1 = 50;
  const x2 = x1 + 100 * Math.cos(angle);
  const y2 = y1 + 100 * Math.sin(angle);
  return { x1: x1 + "%", y1: y1 + "%", x2: x2 + "%", y2: y2 + "%" };
}

function Gradients({ onClick }) {
  const { items, selectedIds, update } = useSVG();
  const [showAll, setShowAll] = useState(true);
  const grads = showAll ? GRADIENTS : GRADIENTS.slice(0, 6);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const ids = selectedIds.length === 0 ? ["background"] : selectedIds;
    for (let id of ids) {
      const item = items[id];
      const def = item.defs?.find((def) => def.id == item.id + "-" + BACKGROUND_DEF_SUFFIX);
      if (!def) return;
      const { x1, y1, x2, y2 } = computeGradientLine(angle);
      def.x1 = x1;
      def.y1 = y1;
      def.x2 = x2;
      def.y2 = y2;
      update({ items });
    }
  }, [angle]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <div style={subtitle}>Gradients</div>
        <div
          onClick={() => setShowAll(!showAll)}
          style={{
            cursor: "pointer",
            fontSize: "0.8rem",
          }}
        >
          See {showAll ? "Less" : "All"}
        </div>
      </div>
      <div
        style={{
          margin: "0px 10px 20px 10px",
        }}
      >
        <Slider fullWidth value={angle} onChange={(e, val) => setAngle(val)} />
      </div>

      <div style={colorsHolder}>
        {grads.map((grad, index) => (
          <Circle
            key={index}
            background={`linear-gradient(to right, ${grad.colors.join(",")})`}
            onClick={() => {
              onClick(grad.colors);
            }}
            name={grad.name}
          />
        ))}
      </div>
    </>
  );
}

function Circle({ background, onClick, name, icon }) {
  return (
    <Tooltip title={name || background} placement="bottom">
      <div
        style={{
          background,
          borderRadius: "50%",
          width: 35,
          height: 35,
          cursor: "pointer",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        {icon}
      </div>
    </Tooltip>
  );
}
