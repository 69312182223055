// @ts-nocheck

import { Cursor } from "../values/enums";
import { getBoundingBox, isSamePoint } from "./utils";

// when a bunch of items are selected, this creates multi-select box for those items
export function createMultiSelectBox(items, selectedIds, getBox) {
  let minX = Infinity,
    minY = Infinity,
    maxEndX = -Infinity,
    maxEndY = -Infinity;

  for (let i = 0; i < selectedIds.length; i++) {
    let box = getBox(selectedIds[i]);
    if (!box) continue;
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxEndX = Math.max(maxEndX, box.x + box.width);
    maxEndY = Math.max(maxEndY, box.y + box.height);
  }

  if (minX == Infinity || minY == Infinity || maxEndX == -Infinity || maxEndY == -Infinity) {
    return;
  }
  const box = {
    x: minX,
    y: minY,
    width: maxEndX - minX,
    height: maxEndY - minY,
  };
  return {
    ...box,
    center: { x: box.x + box.width / 2, y: box.y + box.height / 2 },
    points: getPoints(box),
  };
}

export function getUnmovingPoint(box, cursor) {
  if (!box) {
    console.log("returning unmoving point as null");
    return null;
  }
  const TOPLEFT = { x: box.x, y: box.y };
  const TOPRIGHT = { x: box.x + box.width, y: box.y };
  const BOTTOMRIGHT = { x: box.x + box.width, y: box.y + box.height };
  const BOTTOMLEFT = { x: box.x, y: box.y + box.height };

  let cursorPos;
  if (cursor == Cursor.ResizeNW) cursorPos = "TOPLEFT";
  else if (cursor == Cursor.ResizeNE) cursorPos = "TOPRIGHT";
  else if (cursor == Cursor.ResizeSE) cursorPos = "BOTTOMRIGHT";
  else if (cursor == Cursor.ResizeSW) cursorPos = "BOTTOMLEFT";

  if (cursorPos == "TOPLEFT") return BOTTOMRIGHT;
  else if (cursorPos == "TOPRIGHT") return BOTTOMLEFT;
  else if (cursorPos == "BOTTOMRIGHT") return TOPLEFT;
  else if (cursorPos == "BOTTOMLEFT") return TOPRIGHT;
}

export const findAncestralTransform = (target) => {
  let transform = "";
  while (target) {
    const t = target.getAttribute("transform");
    if (t) {
      transform = t + " " + transform;
    }
    target = target.parentElement;
  }
  return transform;
};

export function getPoints(b) {
  return [
    {
      x: b.x,
      y: b.y,
      cursor: Cursor.ResizeNW,
    },
    {
      x: b.x + b.width,
      y: b.y,
      cursor: Cursor.ResizeNE,
    },
    {
      x: b.x + b.width,
      y: b.y + b.height,
      cursor: Cursor.ResizeSE,
    },
    {
      x: b.x,
      y: b.y + b.height,
      cursor: Cursor.ResizeSW,
    },

    {
      x: b.x + b.width / 2,
      y: b.y,
      cursor: Cursor.ResizeN,
      type: "rect",
    },
    {
      x: b.x + b.width,
      y: b.y + b.height / 2,
      cursor: Cursor.ResizeE,
      type: "rect",
    },
    {
      x: b.x + b.width / 2,
      y: b.y + b.height,
      cursor: Cursor.ResizeS,
      type: "rect",
    },
    {
      x: b.x,
      y: b.y + b.height / 2,
      cursor: Cursor.ResizeW,
      type: "rect",
    },
  ];
}
