import { useRef, useState } from "react";
import { useSVG } from "../SVGContext";
import ImageZoomer, { getPoints } from "../ImageZoomer";
import { getItemFilterString } from "../library/ImageAdjustPanel";
import { OptionsView } from "./VectorView";

export function MpImageView({ item, overlay }) {
  if (!item.imageRect) return null; // qr code

  const { croppingItemId, items, groups, defs, selectedIds, update, addImage } = useSVG();
  const imageRef = useRef(null);
  let [mouseDown, setMouseDownInt] = useState(null);
  let [mouseMove, setMouseMoveInt] = useState(null);

  // if (overlay && croppingItemId !== item.id) return;

  const setMouseDown = (xy) => {
    mouseDown = xy;
    setMouseDownInt(xy);
  };
  const setMouseMove = (xy) => {
    mouseMove = xy;
    setMouseMoveInt(xy);
  };

  const onMouseDown = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    let source = e;
    if (e.touches && e.touches[0]) {
      source = e.touches[0];
    }
    setMouseDown({
      x: source.clientX,
      y: source.clientY,
    });
    setMouseMove({
      x: source.clientX,
      y: source.clientY,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseMove = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    let source = e;
    if (e.touches && e.touches[0]) {
      source = e.touches[0];
    }
    if (mouseDown) {
      const dx = mouseMove.x - source.clientX;
      const dy = mouseMove.y - source.clientY;
      let newCrop = {
        top: +item.crop.top + dy,
        right: +item.crop.right - dx,
        bottom: +item.crop.bottom - dy,
        left: +item.crop.left + dx,
      };
      item.crop = newCrop;
      items[item.id] = item;
      update({ items, groups, defs, selectedIds });

      setMouseMove({
        x: source.clientX,
        y: source.clientY,
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onMouseUp = (e) => {
    if (!overlay || croppingItemId != item.id) return;

    setMouseDown(null);
    setMouseMove(null);
    e.preventDefault();
    e.stopPropagation();
  };

  if (item.type == "mpimage") {
    const frameId = item.customFrame?.id;
    const frameStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      clipPath: frameId ? `url(#${frameId})` : "",
    };

    const [showOptions, setShowOptions] = useState(false);
    const optionsOptions = item.image_options
      ? {
          options: item.image_options,
          item,
          add: (option) => {
            addImage(option, item.id);
          },
        }
      : null;
    return (
      <>
        {overlay && showOptions && optionsOptions && <OptionsView {...optionsOptions} />}

        {item.customFrame && (
          <defs>
            <clipPath id={item.customFrame.id} clipPathUnits="objectBoundingBox" transform={item.customFrame.transformStr} dangerouslySetInnerHTML={{ __html: item.customFrame.innerHTML }}></clipPath>
          </defs>
        )}
        {croppingItemId == item.id && overlay && (
          <image
            x={item.x - item.crop.left}
            y={item.y - item.crop.top}
            height={item.imageRect.height}
            width={item.imageRect.width}
            style={{
              filter: "brightness(0.7)",
            }}
            draggable="false"
            href={item.url}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onTouchStart={onMouseDown}
            onTouchMove={onMouseMove}
            onTouchMoveCapture={onMouseMove}
            onTouchEnd={onMouseUp}
          />
        )}
        <foreignObject
          id={item.id}
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          className="image-foreign-object"
          style={{
            background: overlay && croppingItemId == item.id ? "rgba(0,0,0,0.4)" : "none",
          }}
        >
          <div xmlns="http://www.w3.org/1999/xhtml" style={frameStyle}>
            <div
              style={{
                inset: insetStyle(item.crop),
                height: item.imageRect.height + "px",
                width: item.imageRect.width + "px",
                position: "relative",
              }}
            >
              <img
                ref={imageRef}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  filter: getItemFilterString(item.filter),
                }}
                draggable="false"
                src={item.url}
                alt={item.altText || ""}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onTouchStart={onMouseDown}
                onTouchMove={onMouseMove}
                onTouchMoveCapture={onMouseMove}
                onTouchEnd={onMouseUp}
                onDoubleClick={(e) => {
                  if (overlay) {
                    setShowOptions(!showOptions);
                  }
                }}
                onBlur={() => {
                  setShowOptions(false);
                }}
              ></img>
            </div>
          </div>
        </foreignObject>
        {croppingItemId == item.id && overlay && <ImageZoomer item={item} imageRef={imageRef} />}
      </>
    );
  }
}

export const insetStyle = (crop) => {
  if (!crop || crop.length == 0) return null;
  return -crop.top + "px " + crop.right + "px " + crop.bottom + "px " + -crop.left + "px";
};
