import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import RazorpayPayment from "./RazorpayPayment";
import { LOGIN_URL, useSVG } from "./SVGContext";
import { Button, CircularProgress, Dialog, IconButton, Link, Menu, MenuItem, TextareaAutosize, TextField } from "@mui/material";
import { useState } from "react";
import HambergerMenu from "@mui/icons-material/Menu";
import { EditableDiv } from "./EditableDiv";
import { createDesignServer, req, updateDesignServer } from "./utils/ServerUtils";
import DoneIcon from "@mui/icons-material/Done";
import { SCALE_PREVIEW } from "./values/constants";
import ShareBtn from "./ShareBtn";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import PreviewIcon from "@mui/icons-material/Preview";
import { AiTemplateCreator } from "./AiTemplateCreator";

export function EditorNavBar({}) {
  const { isMobile, design, setDrawerOpen, userInfo } = useSVG();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,

        background: "linear-gradient(33deg, rgb(130 114 255) 30%, rgb(161 5 186) 60%)",
        color: "white",
      }}
    >
      <IconButton
        style={{
          color: "#ccc",
        }}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <HambergerMenu />
      </IconButton>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          justifyContent: "space-between",
          alignItems: "center",

          background: "transparent",
        }}
      >
        {design && (
          <>
            <Undo />
            <Redo />
            {!isMobile && <Title />}
            <AiTemplateCreator />
            {userInfo?.permissions.includes("can_ai_generate") && <Generate />}
            {userInfo?.permissions.includes("can_ai_generate") && <Preview />}
            <ShareBtn />
            <Save />
            <Download />
          </>
        )}

        {!userInfo && (
          <Link
            underline="none"
            variant="button"
            href={LOGIN_URL}
            style={{
              textTransform: "none",
              color: "white",
            }}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}
function Title() {
  const { design, setDesign } = useSVG();
  return (
    <EditableDiv
      text={design.title}
      setText={(t) => {
        setDesign({ ...design, t });
      }}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      className="design-title"
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.target.blur();
        }
        if (e.key === "Escape") {
          e.target.innerText = design.title;
          e.target.blur();
        }
      }}
      onBlur={(e) => {
        setDesign({ ...design, title: e.target.innerText });

        updateDesignServer(design, {
          title: e.target.innerText,
        });
      }}
    />
  );
}
function Download() {
  const [anchorEl, setAnchor] = useState(null);
  const [paymentDialog, setPaymentDialog] = useState(false);
  let [format, setFormat] = useState("png");
  const { fileManager, design, svgRef, items, userInfo, setFullscreenLoading } = useSVG();

  async function download(f) {
    setAnchor(null);
    format = f;
    setFormat(format);
    setPaymentDialog(true);
  }
  async function downloadBacklinkInfographic() {
    setFullscreenLoading(true);
    return fileManager
      .getPreviewImage({
        svgRef,
        width: design.width,
        height: design.height,
        items,
        scale: 2,
      })
      .then(async (canvas) => {
        const base64 = canvas.toDataURL("image/png");
        const blob = await (await fetch(base64)).blob();

        const width = design.width;
        const height = design.height;
        const formData = new FormData();
        formData.append("image", blob, design.title + ".png");
        formData.append("width", width);
        formData.append("height", height);
        formData.append("url", design.metainfo?.url || "");

        req("/infographic", "POST", formData, false)
          .then((res) => {
            setFullscreenLoading(false);
            // copy url to clipboard
            const url = "https://cdn.makemybrand.ai/" + encodeURIComponent(res.s3_key) + "?t=" + Date.now();
            window.open(url, "_blank");
            window.focus(); // Bring window into focus for copy to work
            navigator.clipboard.writeText(url);
          })
          .catch((e) => {
            setFullscreenLoading(false);
            console.error(e);
          });
      })
      .catch((e) => {
        setFullscreenLoading(false);
        console.error(e);
      });
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}
        sx={{
          borderRadius: "12px", // Set the desired border radius
          textTransform: "none",
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          background: "rgb(229 229 229)",
          border: "1px solid white",
          color: "#333",
          borderRadius: 5,
        }}
      >
        <DownloadIcon fontSize="small" />
        Download
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={async () => await download("png")}>Download PNG</MenuItem>
        <MenuItem onClick={async () => await download("pdf")}>Download PDF</MenuItem>

        {userInfo?.permissions.includes("can_ai_generate") && <MenuItem onClick={async () => await downloadBacklinkInfographic()}>Upload Infographic</MenuItem>}
      </Menu>

      <Dialog
        open={paymentDialog}
        onClose={() => setPaymentDialog(false)}
        style={{
          padding: 20,
        }}
      >
        <RazorpayPayment format={format} setPaymentDialog={setPaymentDialog} />
      </Dialog>
    </>
  );
}
function Save() {
  const [anchorEl, setAnchor] = useState(null);
  const [saving, setSaving] = useState(false);
  const { designId, fileManager, svgRef, width, height, items, setFullscreenLoading, design, setDesign, userInfo, getBox, selectedIds } = useSVG();

  function save() {
    setSaving(true);
    fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        items,
        scale: SCALE_PREVIEW,
      })
      .then((canvas) => {
        const base64 = canvas.toDataURL("image/png");
        design.preview = base64;
        setDesign({ ...design });

        updateDesignServer(design)
          .then((res) => {
            setDesign({ ...design, version: res.version });
            setSaving("saved");
            setTimeout(() => {
              setSaving(false);
            }, 3000);
          })
          .catch((e) => {
            setSaving(false);
          });
      });
  }
  function saveAs() {
    setFullscreenLoading(true);

    createDesignServer({
      ...design,
      title: design.title + " (Copy)",
      template_id: design.templateId,
    })
      .then((res) => {
        setFullscreenLoading(true);
        window.location.href = "/design/" + res.design_id;
      })
      .catch((e) => {
        setFullscreenLoading(true);
      });
  }

  return (
    <>
      <IconButton
        onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}
        variant="outline"
        style={{
          color: "#ccc",
        }}
      >
        {saving == true && (
          <CircularProgress
            style={{
              height: 24,
              width: 24,
            }}
          />
        )}
        {saving == false && <SaveIcon />}
        {saving == "saved" && <DoneIcon />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={save}>Save</MenuItem>
        <MenuItem onClick={saveAs}>Save as New</MenuItem>
      </Menu>
    </>
  );
}
function Undo() {
  const { undo } = useSVG();
  return (
    <IconButton
      style={{
        color: "#ccc",
      }}
      onClick={undo}
    >
      <UndoIcon />
    </IconButton>
  );
}
function Redo() {
  const { redo } = useSVG();
  return (
    <IconButton
      style={{
        color: "#ccc",
      }}
      onClick={redo}
    >
      <RedoIcon />
    </IconButton>
  );
}

function Preview() {
  const { setLeftPanel } = useSVG();
  return (
    <>
      <IconButton
        size="small"
        onClick={() => {
          setLeftPanel("preview");
        }}
        style={{
          color: "#ccc",
        }}
      >
        <PreviewIcon />
      </IconButton>
    </>
  );
}

function Generate() {
  const { setFullscreenLoading, design } = useSVG();
  const [showDialog, setShowDialog] = useState(null);
  const [blogUrl, setBlogUrl] = useState("");
  function generate() {
    if (!design.templateId) {
      console.error("Template ID not available");
      return;
    }
    setFullscreenLoading(true);

    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        url: blogUrl,
        template_id: design.templateId,
        dontReplace: true,
      }),
    )
      .then((res) => {
        setFullscreenLoading(false);

        window.open("/design/" + res.design_id, "_blank");
      })
      .catch((e) => {
        setFullscreenLoading(false);
      });
  }
  return (
    <>
      <Button
        size="small"
        variant="contained"
        className="navbar-btn"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
        }}
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Generate!
      </Button>

      <Dialog open={showDialog != null} onClose={() => setShowDialog(null)}>
        <div
          style={{
            height: 500,
            width: 600,

            padding: 20,

            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "start",
            alignItems: "stretch",
          }}
        >
          <TextField
            label="Blog Url"
            variant="outlined"
            fullWidth
            value={blogUrl}
            onChange={(e) => {
              setBlogUrl(e.target.value);
            }}
          />
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            onClick={() => {
              setShowDialog(null);
              generate();
            }}
            fullWidth
          >
            Generate Ideas
          </Button>
        </div>
      </Dialog>
    </>
  );
}
