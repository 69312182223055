import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import TopbarText from "./topbar/TopbarText";
import { TopbarShape } from "./topbar/TopbarShape";
import { TopbarMain } from "./topbar/TopbarMain";
import { TopbarIcon } from "./topbar/TopbarIcon";
import { TopbarImage } from "./topbar/TopbarImage";
import { TopbarCommons } from "./topbar/TopbarCommons";
import "./css/Topbar.css";

export function Topbar({ selectedIds }) {
  const [item, setItem] = useState(null);
  const { items } = useSVG();

  function getType(it) {
    if (it?.proseMirrorData) {
      return "text";
    } else if (it?.type == "shape" || ["rectangle", "ellipse", "circle", "straight", "polyline", "polygon", "path"].includes(it?.type)) {
      return "shape";
    } else if (it?.type == "icon") {
      return "icon";
    } else if (it?.type == "mpimage") {
      return "image";
    }
  }
  useEffect(() => {
    if (Array.from(new Set(selectedIds.map((id) => getType(items[id])))).length == 1) {
      setItem(items[selectedIds[0]]);
    } else {
      setItem(null);
    }
  }, [selectedIds]);

  let bar;
  if (item?.proseMirrorData) {
    bar = <TopbarText item={item} />;
  } else if (item?.type == "shape" || ["rectangle", "ellipse", "circle", "straight", "polyline", "polygon", "path"].includes(item?.type)) {
    bar = <TopbarShape item={item} />;
  } else if (item?.type == "icon") {
    bar = <TopbarIcon item={item} />;
  } else if (item?.type == "mpimage") {
    bar = <TopbarImage item={item} />;
  }
  if (bar) {
    return (
      <>
        {bar}
        <TopbarCommons item={item} />
      </>
    );
  } else {
    return <TopbarMain />;
  }
}
