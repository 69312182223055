import { useEffect } from "react";
import { useSVG } from "../SVGContext";
import { EditorContent } from "@tiptap/react";

export function TextView({ item, overlay }) {
  const { selectedIds, editor, editingId, setEditingId } = useSVG();

  // the way editing works is this:
  // in SVGContext, there is one instance of editor (hook). I set editor content whenever selected item changes. Then here, i'm including editor view only if the item is selected, and only for overlay
  // additionally: text html is generated whenver page is set. this is unnecessary if you can do it in the server once.
  // it is also generated whenever editor content changes.

  if (item.proseMirrorData) {
    const showEditor = editingId == item.id && overlay;
    return (
      // has an overflow visible in css
      <foreignObject
        x={item.x}
        y={item.y}
        width={item.width}
        height={item.height}
        transform={item.transform}
        className="text-foreign-object"
        onDoubleClick={() => {
          setEditingId(item.id);
          editor?.commands.setContent(item.proseMirrorData);
          editor?.commands.focus();
        }}
      >
        <div id={item.id} xmlns="http://www.w3.org/1999/xhtml" style={{ width: "100%", height: "100%" }}>
          {showEditor && <EditorContent editor={editor} />}
          {!showEditor && <div dangerouslySetInnerHTML={{ __html: item.html }} />}
        </div>
      </foreignObject>
    );
  }
}
