// @ts-nocheck

import { DARKTHEME, useSVG } from "./SVGContext";

import { Button, Dialog, Drawer, Link, List, ListItem, ListItemText, ThemeProvider, useMediaQuery } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import SVGContainer from "./SVGContainer";

import Pointer from "./usePointer";
import ShortcutHandler from "./ShortcutHandler";
import { FileUploader } from "./FileUploader";
import LeftPanel from "./LeftPanel";
import LoggedoutTemplates, { LoggedOutTemplates } from "./StackedTemplateCategories";
import { createDesignServer } from "./utils/ServerUtils";
import { MyDrawer } from "./MyDrawer";
import { RightPanel } from "./RightPanel";
import { Cursor } from "./values/enums";

const Editor = memo(() => {
  const { userInfo } = useSVG();
  const { settings, tool, setTool, setCursor, fileManager, items, groups, selectedIds, svgRef, drawerOpen, setDrawerOpen, designId, setDesignId, designTitle, setDesignTitle } = useSVG();
  const provider = useSVG();
  const appRef = useRef(null);
  const pointer = Pointer(provider);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const fileRef = useRef(null);
  const imageRef = useRef(null);
  const startFileUpload = (type) => {
    const ref = type === "place" ? imageRef : fileRef;
    ref.current.value = ""; // Without this, clicking it 2nd time doesn't work
    ref.current.click();
  };

  useEffect(() => {
    if (!tool) {
      setTool(pointer);
    }
  }, [tool]);

  const shortcutHandler = ShortcutHandler({ startFileUpload });

  const { leftPanel, setLeftPanel, label, onTemplateSelect, addShape } = useSVG();
  const leftPanelParams = { leftPanel, setLeftPanel, isMobile, label, onTemplateSelect, addShape };

  // console.log("rendering editor", new Date().getTime());

  return (
    <>
      <div
        ref={appRef}
        tabIndex="-1"
        className="App"
        onKeyDown={(event) => shortcutHandler.handleKeyDown({ event, items, groups, selectedIds, z: provider })}
        onKeyUp={shortcutHandler.handleKeyUp}
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "stretch",
          alignItems: "stretch",

          flexGrow: 1,
        }}
      >
        <ThemeProvider theme={DARKTHEME}>{!isMobile && <LeftPanel {...leftPanelParams} />}</ThemeProvider>
        <SVGContainer onKeyDown={(event) => shortcutHandler.handleKeyDown({ event, items, groups, selectedIds, z: provider })} onKeyUp={shortcutHandler.handleKeyUp} />
        <ThemeProvider theme={DARKTHEME}>{isMobile && <LeftPanel {...leftPanelParams} />} </ThemeProvider>
      </div>

      <FileUploader fileRef={fileRef} imageRef={imageRef} />

      <MyDrawer />
    </>
  );
});

export default Editor;
