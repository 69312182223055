// @ts-nocheck
import jsPDF from "jspdf";
import { DEFTYPES } from "./values/enums";
import { promises } from "dns";
import JSZip from "jszip";
import { SCALE_PDF, SCALE_PNG } from "./values/constants";
import { getAllFontsFromProseMirrorData } from "./utils/utils";
import { allGoogleFonts } from "./values/all-google-fonts";

function FileManager() {
  function ext(filename, ext) {
    return filename.replace(/\.[^/.]+$/, "") + "." + ext;
  }
  function drawOnCanvas(outerHTML, items, width, height, scale) {
    return new Promise((resolve, reject) => {
      try {
        let fontUrls = [];
        let fonts = getAllFontsFromProseMirrorData(
          Object.keys(items)
            .map((k) => items[k].proseMirrorData)
            .filter((it) => it),
        );
        for (let font of fonts) {
          if (allGoogleFonts[font]) {
            fontUrls.push(`https://fonts.googleapis.com/css2?family=${font.replace(/ /g, "+")}&display=swap`);
          }
        }

        Promise.all(fontUrls.map((url) => fetchCSS(url).then((cssText) => embedFonts(cssText)))).then((combinedCss) => {
          let style = document.createElement("style");
          style.innerHTML = combinedCss.join("\n") + ".text-foreign-object {overflow: visible;} p { margin: 0}";

          const div = document.createElement("div");
          div.innerHTML = outerHTML;
          div.firstChild.appendChild(style);

          getSVGHTML(div.firstChild, width, height).then((svgData) => {
            const url = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
            const img = new Image();

            img.setAttribute("crossOrigin", "Anonymous");
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = width * scale;
              canvas.height = height * scale;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width * scale, height * scale);

              resolve(canvas);

              // delete the style tag
              style.remove();
            };
            img.onerror = (err) => {
              console.error("SVG conversion error", err);
              console.log(outerHTML);
              // delete the style tag
              style.remove();
              reject(null);
            };
            img.src = url;
          });
        });
      } catch (e) {
        console.error("Error drawing on canvas", e);
        reject(e);
      }
    });
  }
  const saveAsPdf = (canvases, filename) => {
    const orientation = canvases[0].width > canvases[0].height ? "landscape" : "portrait";
    const pdf = new jsPDF({
      orientation,
      unit: "pt",
      format: [canvases[0].width, canvases[0].height],
    });

    canvases.forEach((canvas, index) => {
      try {
        const imgData = canvas.toDataURL("image/png", 1.0);
        const width = canvas.width;
        const height = canvas.height;
        if (index > 0) pdf.addPage([width, height]);
        pdf.addImage(imgData, "PNG", 0, 0, width, height);
      } catch (e) {
        console.error("Error adding image to pdf", e);
      }
    });

    pdf.save(ext(filename, "pdf"));
  };

  const getSVGHTML = async (svgDiv, width, height) => {
    const images = svgDiv.querySelectorAll("img");
    let promises = [];

    for (let img of images) {
      let src = img.getAttribute("src");
      const amp = src.includes("?") ? "&" : "?";
      src += amp + "t=" + new Date().getTime(); // add a timestamp to avoid browser caching
      if (src.startsWith("data:image")) continue;
      const promise = fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              img.oldSrc = img.src;
              img.src = reader.result;
              resolve();
            };
            reader.readAsDataURL(blob);
          });
        })
        .catch((e) => console.error("Error fetching image", e));

      promises.push(promise);
    }

    await Promise.all(promises);

    // BUG FIX: you have to manually close <img> tags
    let html = svgDiv.outerHTML;
    html = html.replace(/<img(.*?)>/g, "<img$1 />");
    html = html.replace(/&nbsp;/g, " ");

    // set the viewbox to "0 0 width height"
    html = html.replace(/viewBox="[^"]*"/, `viewBox="0 0 ${width} ${height}"`);

    // parse this using a parser, in order to remove the overlay
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "image/svg+xml");

    // delete a <g> with id overlay
    const overlay = doc.querySelector("#overlay");
    if (overlay) {
      overlay.remove();
    }
    html = new XMLSerializer().serializeToString(doc);

    // restore the original srcs (not inlined)
    for (let img of images) {
      if (img.oldSrc) {
        img.src = img.oldSrc;
        img.oldSrc = null;
      }
    }
    return html;
  };

  function startDownload(blob, fileName) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function downloadZip(blobs, filename, extension) {
    if (blobs.length == 1) {
      startDownload(blobs[0], filename + "." + extension);
      return;
    }
    const zip = new JSZip();
    const folder = zip.folder("images");

    blobs.forEach((blob, index) => {
      folder.file(`${filename}-${index + 1}.${extension}`, blob);
      if (index === blobs.length - 1) {
        zip.generateAsync({ type: "blob" }).then((blob) => {
          startDownload(blob, filename + ".zip");
        });
      }
    });
  }
  const canvasBlobPromise = (canvas) => {
    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      } catch (e) {
        console.error("Error creating blob", e);
        reject(e);
      }
    });
  };
  const getPreviewImage = async (params) => {
    let { svgRef, items, width, height, scale } = params;
    return drawOnCanvas(svgRef.current.outerHTML, items, width, height, scale);
  };
  const save = async (params, setSelectedPage, cb) => {
    let { type, pageCount, svgRef, items, width, height, filename, scale } = params;
    filename = toFilename(filename);
    const promises = [];
    for (let i = 0; i < pageCount; i++) {
      setSelectedPage(i);
      await new Promise((resolve) => setTimeout(resolve, 500));
      const outerHTML = svgRef.current.outerHTML;

      if (type == "png") {
        promises.push(drawOnCanvas(outerHTML, items, width, height, SCALE_PNG).then((canvas) => canvasBlobPromise(canvas)));
      } else if (type == "pdf") {
        promises.push(drawOnCanvas(outerHTML, items, width, height, SCALE_PDF));
      }
    }
    Promise.all(promises).then((canvasesOrBlobs) => {
      try {
        if (type == "png") {
          downloadZip(canvasesOrBlobs, filename, "png");
        } else if (type == "pdf") {
          saveAsPdf(canvasesOrBlobs, filename);
        }
      } catch (e) {
        console.error("Error saving", e);
      }
      cb();
    });
  };

  return { save, getPreviewImage };
}
export default FileManager;

function fetchCSS(url) {
  return fetch(url).then(
    function (res) {
      return res.text();
    },
    function (e) {
      console.log(e);
    },
  );
}

function embedFonts(cssText) {
  var fontLocations = cssText.match(/https:\/\/[^)]+/g);
  var fontLoadedPromises = fontLocations.map(function (location) {
    return new Promise(function (resolve, reject) {
      fetch(location)
        .then(function (res) {
          return res.blob();
        })
        .then(function (blob) {
          var reader = new FileReader();
          reader.addEventListener("load", function () {
            // Side Effect
            cssText = cssText.replace(location, this.result);
            resolve([location, this.result]);
          });
          reader.readAsDataURL(blob);
        })
        .catch((e) => {
          console.error("Error fetching font", e);
          reject(e);
        });
    });
  });
  return Promise.all(fontLoadedPromises).then(function () {
    return cssText;
  });
}

function toFilename(title) {
  if (!title) return "download";
  return title
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
}
