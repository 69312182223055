import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete, Menu, ListItem, List, Button, Select, InputAdornment, ImageList, MenuItem, CircularProgress, Dialog } from "@mui/material";
import { cap, getTemplatePreviewUrl } from "./utils/utils";
import { req, searchTemplates } from "./utils/ServerUtils";
import { LazyImageWithAddBtn } from "./library/LazyImageWithAddBtn";
import { useSVG } from "./SVGContext";
import Masonry from "@mui/lab/Masonry";
import Gallery from "./Gallery";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BookmarkedTemplates = memo(() => {
  const [isBookmarked, setIsBookmarked] = useState({});

  const [results, setResults] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const { isMobile } = useSVG();

  useEffect(() => {
    req("/bookmarks").then((res) => {
      const b = {};
      for (let r of res) {
        b[r.id] = true;
      }
      setIsBookmarked(b);
      setResults(res);
    });
  }, []);

  function bookmark(id) {
    setIsBookmarked({ ...isBookmarked, [id]: !isBookmarked[id] });
    const method = !isBookmarked[id] ? "POST" : "DELETE";
    req("/bookmarks/" + id, method, JSON.stringify({ id })).catch((err) => {
      console.error(err);
      setIsBookmarked({ ...isBookmarked, [id]: !isBookmarked[id] });
    });
  }

  return (
    <div
      style={{
        padding: 15,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        alignItems: "stretch",

        height: "100%",
        overflow: isMobile ? "hidden" : "unset",
      }}
    >
      <Pages currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
      <Results results={results} isSearching={isSearching} isBookmarked={isBookmarked} bookmark={bookmark} />
    </div>
  );
});
export default BookmarkedTemplates;

function Pages({ currentPage, setCurrentPage, totalPages }) {
  const pageStart = Math.floor((currentPage - 1) / 5) * 5 + 1;
  const pageList = Array.from(
    {
      length: Math.min(5, totalPages - pageStart + 1),
    },
    (_, i) => pageStart + i,
  );

  if (pageList.length <= 1) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <IconButton
        onClick={() => {
          setCurrentPage(currentPage - 5 >= 1 ? currentPage - 5 : 1);
        }}
        disabled={pageList[0] <= 1}
      >
        {"<"}
      </IconButton>
      {totalPages > 0 &&
        pageList.map((p, i) => (
          <Button
            size="small"
            key={i}
            onClick={() => {
              setCurrentPage(p);
            }}
            color="primary"
            style={{
              color: p === currentPage ? "red" : "black",
              minWidth: 50,
            }}
          >
            {p}
          </Button>
        ))}

      <IconButton
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}
        disabled={pageList[pageList.length - 1] >= totalPages - 1}
      >
        {">"}
      </IconButton>
    </div>
  );
}

function Searchbox({ query, setQuery, search }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        fullWidth
        value={query}
        variant="outlined"
        onChange={(e) => {
          query = e.target.value;
          setQuery(query);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            search();
          }
        }}
        placeholder="Search thousands of templates"
      />
    </div>
  );
}

function Results({ results, isSearching, isBookmarked, bookmark }) {
  const { isMobile, onTemplateSelect } = useSVG();
  const [urlDialog, setUrlDialog] = useState(false);
  const [url, setUrl] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [loading, setLoading] = useState(false);

  const onTemplateClick = (id) => {
    setTemplateId(id);
    setUrlDialog(true);
  };

  const open = () => {
    setLoading(true);
    req(
      "/generatev2",
      "POST",
      JSON.stringify({
        url,
        template_id: templateId,
      }),
    ).then((res) => {
      window.open("/design/" + res.design_id, "_blank");
    });
  };

  const createDesign = () => {
    onTemplateSelect(templateId);
  };

  const pageWidth = window.innerWidth - 50;
  const width = isMobile ? pageWidth : pageWidth / 3;
  return (
    <>
      <Dialog open={urlDialog} onClose={() => setUrlDialog(false)}>
        <div
          style={{
            width: 500,
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            gap: 10,
            padding: 20,
          }}
        >
          <TextField label="Blog URL" fullWidth value={url} onChange={(e) => setUrl(e.target.value)} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "stretch",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Button
              onClick={open}
              style={{
                textTransform: "none",
                flexGrow: 1,
              }}
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : "Generate InfoGraphic"}
            </Button>
            <IconButton onClick={createDesign}>
              <OpenInNewIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          background: "rgba(255,255,255,0.7)",

          visibility: isSearching ? "visible" : "hidden",
        }}
      >
        <CircularProgress />
      </div>
      <Gallery
        style={{
          overflowY: isMobile ? "scroll" : "unset",
          width: "100%",
          maxWidth: "100%",
        }}
        data={results}
        render={(item, itemWidth) => <TemplateSearchResult item={item} itemWidth={itemWidth} isMobile={isMobile} onTemplateSelect={onTemplateClick} isBookmarked={isBookmarked} bookmark={bookmark} />}
      />
    </>
  );
}
function TemplateSearchResult({ item, itemWidth, isMobile, onTemplateSelect, isBookmarked, bookmark }) {
  const ar = item.width / item.height;
  const itemHeight = itemWidth / ar;
  return <LazyImageWithAddBtn key={item.id} src={getTemplatePreviewUrl(item.id)} alt={item.title} width={isMobile ? "100%" : itemWidth} height={isMobile ? "auto" : itemHeight} onClick={() => onTemplateSelect(item.id)} addString={"Create Design"} isBookmarked={isBookmarked[item.id]} bookmark={() => bookmark(item.id)} />;
}
