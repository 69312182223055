// @ts-nocheck
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Cursor, DEFTYPES } from "./values/enums";

import useFileManager from "./FileManager";
import History from "./History";
import { copy, cut, paste } from "./utils/ClipboardUtils";
import { Group } from "./Group";
import { createMultiSelectBox } from "./utils/PointerUtils";
import { LABELS } from "./values/labels";
import { arrange } from "./utils/ArrangeUtils";
import { useIdGenerator } from "./IdGenerator";
import { ARROW_MARKER_DEFS, FRAME_DEFS, ROOT_GROUP_ID, SCALE_PREVIEW, SITEROOT } from "./values/constants";
import { snap, snapAngle, snapItem, snapMouse } from "./utils/SnapUtils";
import { getSVGBoundingBox, getTemplatePreviewUrl, getZoomLevel, rand } from "./utils/utils";
import { allGoogleFonts } from "./values/all-google-fonts";
import { fetchDesign, req, updateDesignServer, createDesignFromTemplateServer, fetchBlock } from "./utils/ServerUtils";
import { createTheme, useMediaQuery } from "@mui/material";
import { getCoordsInSvg } from "./utils/utils";
import { generateHTML, useEditor } from "@tiptap/react";
import { EditorExtensions } from "./views/Tiptap";
import { GoogleFontFamilies, WebSafeFonts } from "./values/GoogleFontFamilies";
import { frames } from "./topbar/TopbarImage";
import { getTransformStr } from "./utils/transformUtils";
import { useServer } from "./useServer";
import { useParams } from "react-router-dom";
import WebFont from "webfontloader";

const mainGroup = new Group(ROOT_GROUP_ID);

const isDev = window.location.href.includes("localhost");

let subdomain = window.location.host.split(".")[0];
if (subdomain == "makemybrand") subdomain = null;
export const LOGIN_URL = "/login" + (subdomain ? "?from=" + subdomain : "");
console.log("login url:", LOGIN_URL, subdomain);

const SVGContext = createContext({});
export const SVGProvider = ({ children }) => {
  const [items, setItems] = useState({});
  const [groups, setGroups] = useState({ [ROOT_GROUP_ID]: mainGroup }); // FIXME, use constants
  const [defs, setDefs] = useState([]);
  const [selectedIds, setSelectedIdsLocal] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [outline, setOutline] = useState(null);

  const [multiSelectBox, setMultiSelectBox] = useState(null);

  const [shouldSnap, setShouldSnap] = useState(true);

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(600);
  let [viewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    width: width,
    height: height,
  });

  const svgRef = React.useRef<SVGSVGElement>(null);

  const [tool, setToolInternal] = useState(null);
  const [cursor, setCursor] = useState(Cursor.Default);

  const [fill, setFill] = useState("transparent");
  const [stroke, setStroke] = useState("black");

  const [resizeRect, setResizeRect] = useState(null);

  const [leftPanel, setLeftPanel] = React.useState(null);

  const [snapLines, setSnapLines] = useState([]);
  const [showGrid, setShowGrid] = useState(false);

  const [userInfo, setUserInfo] = React.useState(null);
  const [designs, setDesigns] = React.useState(null);
  const [design, setDesignInternal] = React.useState(null);

  const [replacingItemId, setReplacingItemId] = React.useState(null);
  const [croppingItemId, setCroppingItemId] = React.useState(null);

  const [selectedPage, setSelectedPage] = React.useState(null);
  const [fullscreenLoading, setFullscreenLoading] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  let [zoomLevel, setZoomLevel] = useState(100);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [saving, setSaving] = useState(false);
  const [isPointerOrPan, setIsPointerOrPan] = useState(false);
  const [notification, setNotification] = useState(null);

  function getFontDefs(fonts) {
    return fonts.map((font) => {
      return {
        type: DEFTYPES.IMPORTURL,
        url: `https://fonts.googleapis.com/css2?family=${font.replace(" ", "+")}&display=swap`,
      };
    });
  }

  function setDesign(d) {
    setDesignInternal(d);
  }

  useEffect(() => {
    if (design?.pages && design?.pages[selectedPage]) {
      let page = design.pages[selectedPage];

      update({
        items: page.items,
        groups: page.groups,
        defs: page.defs,
        selectedIds: [],
      });
      setWidth(page.width);
      setHeight(page.height);
      setViewBox({ ...page.viewbox });
    }
  }, [design, selectedPage]);

  const clearTopbarSelectedButtons = () => {
    setCroppingItemId(null);
    setReplacingItemId(null);
    setLeftPanel(null);
  };
  const setSelectedIds = (indices) => {
    setSelectedIdsLocal(indices);

    const box = createMultiSelectBox(items, indices, getBox);
    setMultiSelectBox(box);
    clearTopbarSelectedButtons();
    if (indices.length == 1) {
      const item = items[indices[0]];
      if (item?.proseMirrorData) {
        // editor?.commands.focus();
      }
    }
  };
  function fixMatters(items) {
    // delete once fixed on server
    for (let key in items) {
      if (items[key].imageRect) {
        items[key].imageRect.width = items[key].imageRect.width || items[key].imageRect.widthPx;
        items[key].imageRect.height = items[key].imageRect.height || items[key].imageRect.heightPx;
      }
      if (items[key].frame) {
        const frame = frames.find((f) => f.name.toLowerCase() == items[key].frame.toLowerCase());
        if (!frame) {
          console.log("Frame not found", items[key].frame);
          continue;
        }
        items[key].customFrame = {
          innerHTML: frame.innerHTML,
          id: items[key].id + "-frame",
          scale: { x: 1, y: 1 },
        };
      }

      if (items[key].proseMirrorData) {
        items[key].html = generateHTML(items[key].proseMirrorData, EditorExtensions);
      }
    }
    const newItems = {};
    for (let key in items) {
      const it = {
        ...items[key],
        id: "" + key,
      };
      newItems[it.id] = it;
    }
    return newItems;
  }

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/login") || url.includes("/register")) {
      return;
    }
    req(`/user_info`)
      .then(setUserInfo)
      .catch((error) => {
        window.location.href = LOGIN_URL;
        console.error("Error fetching user info:", error);
        setUserInfo(null);
      });

    const location = window.location;
    const designId = location.pathname.split("?")[0].split("/design/")[1];
    const blockId = location.pathname.split("?")[0].split("/block/")[1];

    let promise;
    if (designId) promise = fetchDesign(designId);
    if (blockId) promise = fetchBlock(blockId);

    if (promise) {
      promise
        .then((data) => {
          console.log("Data fetched successfully!", data);
          let pages;
          if (data.design_json?.length > 0) {
            // for old designs
            const page = data.design_json[0];
            if (page && page.width) {
              setDesign({
                ...data,
                width: page.width,
                height: page.height,
                preview: page.preview,
                title: data.title || page.title,
                pages: data.design_json,
                id: data.id,
                version: data.version,
                templateId: data.template_id,
              });
              pages = data.design_json;
            } else {
              console.error("Width not found");
            }
          } else if (data.pages) {
            setDesign(data);
            pages = data.pages;
          }

          for (let p of pages) {
            p.items = fixMatters(p.items);
          }
          // if these are not already in the fonts list, add them
          pages
            .map((page) => page.fonts)
            .flat()
            .forEach((font) => {
              if (allGoogleFonts[font] && fonts.indexOf(font) == -1) {
                fonts.push(font);
              }
            });
          setFonts([...fonts]);

          setTimeout(() => {
            WebFont.load({
              google: {
                families: fonts,
              },
            });
          }, 100);

          setSelectedPage(0);
        })
        .catch((error) => {
          console.error("Error fetching design:", error);
          setDesign(null);
        });
    }
  }, []);

  const onTemplateSelect = useCallback(
    (id) => {
      setFullscreenLoading(true);
      if (!userInfo) {
        setFullscreenLoading(false);
        window.location.href = LOGIN_URL;
        return;
      }
      createDesignFromTemplateServer(id)
        .then((res) => {
          setFullscreenLoading(false);
          // new window
          window.open(`/design/${res.design_id}`, "_blank");
        })
        .catch((e) => {
          setFullscreenLoading(false);
        });
    },
    [userInfo],
  );

  let [updateQueue, setUpdateQueue] = useState([]);
  let [saveDebounceTimeout, setSaveDebounceTimeout] = useState(null);
  function saveToServerDebounced() {
    if (saveDebounceTimeout) {
      clearTimeout(saveDebounceTimeout);
    }
    saveDebounceTimeout = setTimeout(() => {
      saveToServer().then(() => {
        setUpdateQueue([]);
      });
    }, 10000);
    setSaveDebounceTimeout(saveDebounceTimeout);
  }
  function queueToServer(update) {
    console.log("not saving yet...");
    // updateQueue.push(update);
    // setUpdateQueue([...updateQueue]);
    // saveToServerDebounced();
  }

  // when selectedIds is changed, multibox has to be updated
  let [debounceTimeout, setDebounceTimeout] = useState(null);
  useEffect(() => {
    if (selectedIds.length < 10) {
      // debounce
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        // setMultiSelectBox(createMultiSelectBox(selectedIds, getBox));
        const fills = Array.from(new Set(selectedIds.map((i) => items[i]?.fill)));
        const strokes = Array.from(new Set(selectedIds.map((i) => items[i]?.stroke)));
        if (fills.length == 1) setFill(fills[0]);
        if (strokes.length == 1) setStroke(strokes[0]);
      }, 50);
      setDebounceTimeout(debounceTimeout);
    }
  }, [selectedIds, items, groups]);

  const addToGroup = (item, group = mainGroup) => {
    item.group = group.id;
    groups[group.id].children.push(item.id);
    setGroups({ ...groups });
    return item;
  };
  const deleteFromGroup = (item) => {
    const group = groups[item.group];
    const idx = group.children.findIndex((x) => x == item.id);
    group.children.splice(idx, 1);
    groups[item.group] = group;
    setGroups({ ...groups });

    delete item.group;
    return item;
  };

  /********** MANAGERS ************/
  const idGenerator = useIdGenerator();
  const fileManager = useFileManager();
  const history = History({ setItems });

  const setTool = (nextTool) => {
    if (tool && tool.onToolUnselect) {
      tool.onToolUnselect(items, selectedIds);
    }

    tool && tool.decorateItem && removeDecorator(tool);
    tool && tool.decorateSvg && removeSvgDecorator(tool);

    setToolInternal(nextTool);
    if (nextTool && nextTool.onToolSelect) {
      nextTool.onToolSelect(items, selectedIds);
    }
    nextTool && nextTool.decorateItem && addDecorator(nextTool);
    nextTool && nextTool.decorateSvg && addSvgDecorator(nextTool);
  };

  /*********************************** saving & loading json, svg etc *******************/

  function changeDimensions(args) {
    args.width = parseInt(("" + args.width).replace("px", "")) || 500;
    args.height = parseInt(("" + args.height).replace("px", "")) || 500;
    setWidth(args.width);
    setHeight(args.height);
    args.viewbox = args.viewbox || {
      x: 0,
      y: 0,
      width: args.width,
      height: args.height,
    };
    setViewBox({ ...args.viewbox });
  }
  function addText(text, proseMirrorData) {
    const item = {
      id: "text-" + rand(),
      type: "text",
      x: 0,
      y: 0,
      width: 300,
      height: 100,
      proseMirrorData: proseMirrorData,
      html: generateHTML(proseMirrorData, EditorExtensions),
    };
    items[item.id] = item;
    item.group = ROOT_GROUP_ID;
    groups[ROOT_GROUP_ID].children.push(item.id);

    update({
      item,
      groups,
      selectedIds: [item.id],
    });
  }

  function addImage(image, replaceWhich) {
    setFullscreenLoading(true);

    let w, h;
    if (image.width) {
      w = width / 2;
      const aspectRatio = image.width && image.height ? image.width / image.height : 1;
      h = w / aspectRatio;
    } else {
      w = width / 2;
      h = height / 2;
    }
    const amp = image.src.includes("?") ? "&" : "?";
    const src = image.src + amp + "t=" + new Date().getTime();

    const imageItem = {
      id: replacingItemId || "mpimage-" + rand(),
      type: "mpimage",
      x: 0,
      y: 0,
      width: w,
      height: h,
      url: image.src,
      crop: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      imageRect: {
        height: h,
        width: w,
      },
    };
    replace(imageItem, replacingItemId || replaceWhich);
    setReplacingItemId(null);

    setFullscreenLoading(false);

    setTimeout(() => {
      fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const src = reader.result;
            if (items[imageItem.id]) {
              items[imageItem.id].url = src;
              update({ items });
            }
          };
          reader.readAsDataURL(blob);
        })
        .catch((e) => {
          console.error("Error fetching image", e);
        });
    }, 0);
  }

  function fetchAndProcessVector(item) {
    return fetch(item.svg.src)
      .then((res) => res.text())
      .then((svg) => {
        const props = extractSvgProps(svg);
        return {
          ...item,
          ...props,
        };
      })
      .catch((e) => {
        console.error("Error fetching vector", e);
        throw e;
      });
  }

  function extractSvgProps(svg) {
    // note: this same function exists in server-side preprocessing code, in py, for venngage icons
    // note: expects item to have svg, width, height

    svg = svg.replace(/\n/g, ""); // \n causes regex matching issues

    const svgTag = svg.match(/<svg[^>]*>/)[0];

    // extract viewbox
    let viewbox = mat(svgTag, / viewBox="([^"]*)"/i);
    // item.viewbox = item.viewbox || viewbox;
    // if (!item.viewbox || item.viewbox.split(" ").length != 4) {
    //   // if no viewbox or invalid viewbox, set it to 0 0 width height
    //   item.viewbox = `0 0 ${item.width} ${item.height}`;
    // }

    // extract fill, stroke and stroke-width
    const fill = mat(svgTag, / fill="([^"]*)"/i);
    const stroke = mat(svgTag, / stroke="([^"]*)"/i);
    const strokeWidth = mat(svgTag, / stroke-width="([^"]*)"/i);

    // finally, delete the svg tag and just keep the inner code
    svg = mat(svg, /<svg[^>]*>(.*)<\/svg>/);

    return {
      svg,
      viewbox,
      fill,
      stroke,
      strokeWidth,
    };
  }

  const addShape = (shape) => {
    const item = {
      type: "shape",
      id: "shape-" + rand(),
      ...shape,
      mpTransforms: {
        translate: { x: width / 2, y: height / 2 },
        scale: shape.scale,
      },
      opacity: 1,
    };
    items[item.id] = item;
    item.group = ROOT_GROUP_ID;
    groups[ROOT_GROUP_ID].children.push(item.id);

    update({
      items,
      groups,
      selectedIds: [item.id],
    });

    console.log("added item", item);
  };
  function addVector(svg, replacingItemIdOverride) {
    // while giving svg icon options, there's some race condition: setting replacingItemId and then calling
    // addVector doesn't work. So I'm allowing the option to pass that replacingItemIdOverride to addVector & replace
    fetchAndProcessVector({
      type: "vector",
      id: "vector-" + rand(), // will get overwritten if replacing
      x: width / 2,
      y: height / 2,
      height: height / 3,
      width: width / 3,
      fill: "black",
      stroke: "black",
      strokeWidth: 1,
      svg: svg,
    }).then((it) => {
      replace(it, replacingItemIdOverride || replacingItemId);
      setReplacingItemId(null);
    });
  }

  function replace(item, replaceWhich) {
    if (replaceWhich) {
      // store image height & width - is this available for svg?
      const imageWidth = item.width;
      const imageHeight = item.height;
      console.log("image dims", imageWidth, imageHeight);

      // delete this
      const oldItem = items[replaceWhich];

      const aspectRatio = item.width / item.height;
      // set same props
      for (const key of ["x", "y", "height", "width", "fill", "stroke", "strokeColor", "rotateDeg", "group", "frame", "mpTransforms", "mpTransformStr", "type"]) {
        item[key] = oldItem[key];
      }

      // re imageRect, keep the aspect ratio
      if (oldItem.imageRect) {
        const imageAspectRatio = imageWidth / imageHeight;
        const scale = imageWidth / item.imageRect.width;
        item.imageRect.width = oldItem.imageRect.width;
        item.imageRect.height = imageHeight * scale;

        if (item.imageRect.height < oldItem.imageRect.height) {
          item.imageRect.height = oldItem.imageRect.height;
          item.imageRect.width = item.imageRect.height * imageAspectRatio;
        }
      }

      item.id = replaceWhich;
      items[replaceWhich] = item;
    } else {
      item.group = ROOT_GROUP_ID;
      items[item.id] = item;
      groups[ROOT_GROUP_ID].children.push(item.id);
    }

    update({ item, groups, selectedIds });

    return item;
  }

  /***************** items, groups, defs management ************************/

  function addItems(its, msg = "add items") {
    for (let it of its) {
      items[it.id] = it;
      it.group = it.group || mainGroup.id;
      groups[it.group].children.push(it.id);
    }
    const ids = its.map((i) => i.id);
    update({ items, groups, defs, selectedIds: ids });
  }

  function deleteItems(ids, msg = "delete items") {
    for (let i of ids) {
      const item = items[i];
      if (!item) {
        console.log("Item not found", i);
        continue;
      }
      if (!item.group) continue; // shapebuilder items
      const idx = groups[item.group].children.findIndex((x) => x == item.id);
      groups[item.group].children.splice(idx, 1);
    }
    for (let i of ids) {
      delete items[i];
    }
    for (let id in groups) {
      if (id != ROOT_GROUP_ID && groups[id].children.length == 0) {
        delete groups[id];
      }
    }

    update({ items, groups, selectedIds: [] });

    console.log("deleted items", ids);
    return { items, groups };
  }

  function modifyDef(def) {
    const idx = defs.findIndex((d) => d.id === def.id);
    if (idx > -1) {
      defs[idx] = def;
      setDefs([...defs]);
    }
  }

  // this is just for showing in the top bar
  const [fonts, setFonts] = useState([...WebSafeFonts, ...GoogleFontFamilies]);
  const [editorState, setEditorState] = useState({
    font: "Arial",
    color: "black",
    fontSize: "12px",
  });

  const editor = useEditor({
    extensions: EditorExtensions,

    onUpdate: ({ editor }) => {
      if (!items[selectedIds[0]]?.proseMirrorData) return;

      items[selectedIds[0]].proseMirrorData = editor.getJSON();
      items[selectedIds[0]].html = editor.getHTML();
      setItems({ ...items });

      setEditorState({
        ...editorState,
        font: editor.getAttributes("textStyle").fontFamily || "Arial",
        color: editor.getAttributes("textStyle").color || "black",
        fontSize: editor.getAttributes("textStyle").fontSize || "12px",
      });
    },
    onSelectionUpdate: ({ editor }) => {
      setTimeout(() => {
        setEditorState({
          ...editorState,
          font: editor.getAttributes("textStyle").fontFamily || "Arial",
          color: editor.getAttributes("textStyle").color || "black",
          fontSize: editor.getAttributes("textStyle").fontSize || "12px",
        });
      }, 10);
    },
    onBlur: ({ editor, event }) => {
      setEditingId(null);
    },
  });

  useEffect(() => {
    // editor?.commands.setContent(items[selectedIds[0]]?.proseMirrorData);
  }, [selectedIds]);

  // zoom
  function resetZoom() {
    setZoomLevel(100);
    setViewBox({ x: 0, y: 0, width: width, height: height });
  }

  function zoom(xy, zlevel) {
    if (zlevel < 50) zlevel = 50;
    if (zlevel > 200) zlevel = 200;

    viewBox.width = (width * zlevel) / 100;
    viewBox.height = (height * zlevel) / 100;

    const zfactor = width / viewBox.width;
    viewBox.x = width / 2 - viewBox.width / 2;
    viewBox.y = height / 2 - viewBox.height / 2;

    zoomLevel = zlevel;
    setZoomLevel(zlevel);
    viewBox = {
      x: viewBox.x,
      y: viewBox.y,
      width: viewBox.width,
      height: viewBox.height,
    };
    setViewBox({ ...viewBox });
  }

  function pan(dx, dy) {
    viewBox = {
      x: viewBox.x + dx,
      y: viewBox.y + dy,
      width: viewBox.width,
      height: viewBox.height,
    };
    setViewBox(viewBox);
  }

  function zoomIn(xy) {
    zoom(xy, zoomLevel - 1);
  }
  function zoomOut(xy) {
    zoom(xy, zoomLevel + 1);
  }

  function resize(w, h) {
    w = parseInt(w);
    h = parseInt(h);

    let bg = items["background"];
    bg.width = w;
    bg.height = h;
    items[bg.id] = bg;
    design.width = w;
    design.height = h;
    design.pages[selectedPage].width = w;
    design.pages[selectedPage].height = h;

    update({ items });
    changeDimensions({ width: w, height: h });
    setDesign({ ...design });
    setWidth(w);
    setHeight(h);
  }

  function update(params, addToHistory = true) {
    if (params.items) {
      setItems({ ...params.items });
      design.pages[selectedPage].items = params.items;
      // setDesign({ ...design }); // will cause an infinite loop

      if (params.changed_item_ids) {
        for (let id of params.changed_item_ids) {
          queueToServer({
            item_id: id,
            item: params.items[id],
            page_index: selectedPage,
          });
        }
      } else {
        // reduce how many times this happens
        design.pages[selectedPage].items = params.items;
        // setDesign({ ...design }); // will cause an infinite loop
        queueToServer({
          items: params.items,
          page_index: selectedPage,
        });
      }
    }
    if (params.groups) {
      setGroups({ ...params.groups });
      design.pages[selectedPage].groups = params.groups;
      // setDesign({ ...design });

      queueToServer({
        groups: params.groups,
        page_index: selectedPage,
      });
    }

    if (params.defs) {
      setDefs([...params.defs]);
      design.pages[selectedPage].defs = params.defs;
      // setDesign({ ...design });

      queueToServer({
        defs: params.defs,
        page_index: selectedPage,
      });
    }
    // params.selectedIds && setSelectedIds(params.selectedIds);

    if (addToHistory) {
      history.add(params);
    }
  }

  function getBox(itemId) {
    return getSVGBoundingBox(svgRef, document.getElementById(itemId));
  }

  function saveToServer() {
    if (!design) {
      console.error("Design not found");
      return Promise.resolve();
    }
    setSaving(true);
    return fileManager
      .getPreviewImage({
        svgRef,
        width,
        height,
        items,
        scale: SCALE_PREVIEW,
      })
      .then((canvas) => {
        const base64 = canvas.toDataURL("image/png");
        return updateDesignServer({
          ...design,
          preview: base64,
        })
          .then(() => {
            setSaving("saved");
            setTimeout(() => {
              setSaving(false);
            }, 3000);
          })
          .catch((e) => {
            setSaving(false);
          });
      });
  }

  const [cache, setCache] = useState({});
  const server = useServer({ cache, setCache });

  return (
    <SVGContext.Provider
      value={{
        server,
        items,
        groups,
        defs,

        update,

        selectedIds,
        setSelectedIds,
        editingId,
        setEditingId,

        width,
        height,
        svgRef,

        multiSelectBox,
        setMultiSelectBox,

        history: history,
        undo: history.undo,
        redo: history.redo,

        resetZoom,
        zoom,
        zoomIn,
        zoomOut,
        zoomLevel,
        setZoomLevel,
        pan,

        viewBox: viewBox,
        setViewBox: setViewBox,
        tool,
        setTool,

        cursor,
        setCursor,

        fileManager: fileManager,

        fill,
        setFill,
        stroke,
        setStroke,

        cut: () => cut({ items, addItems, selectedIds, deleteItems }),
        copy: (asFormat = "json") =>
          copy({
            asFormat,
            items,

            selectedIds,
            svgRef,
            fileManager,
            width,
            height,
          }),
        paste: () =>
          paste({
            items,
            changeDimensions,
            addItems,
          }),

        resizeRect,
        setResizeRect,
        changeDimensions,

        outline,
        setOutline,

        label,

        idGenerator,
        id: idGenerator.id,

        addToGroup: addToGroup,
        deleteFromGroup: deleteFromGroup,

        deleteItems,
        addItems,
        modifyDef,

        leftPanel,
        setLeftPanel,

        snapLines,
        setSnapLines,
        shouldSnap,
        setShouldSnap,

        showGrid,
        setShowGrid,

        toSvgUnits: (px) => getZoomLevel(svgRef) * px,

        mousePos,
        setMousePos,

        replacingItemId,
        setReplacingItemId,

        userInfo,
        designs,
        design,
        setDesign,

        addImage,
        addText,
        addShape,
        addVector,

        croppingItemId,
        setCroppingItemId,
        clearTopbarSelectedButtons,

        selectedPage,
        setSelectedPage,

        onTemplateSelect,

        fullscreenLoading,
        setFullscreenLoading,

        isMobile,
        drawerOpen,
        setDrawerOpen,

        editor: editor,
        editorState,
        setEditorState,
        fonts,

        getBox,

        selectedPoint,
        setSelectedPoint,

        saving,
        setSaving,
        saveToServer,

        isPointerOrPan,
        setIsPointerOrPan,

        notification,
        setNotification,
        resize,
      }}
    >
      {children}
    </SVGContext.Provider>
  );
};

export const useSVG = () => useContext(SVGContext);

function toCamelCase(obj) {
  for (let key in obj) {
    let newKey = key.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    if (newKey != key) {
      obj[newKey] = obj[key];
      delete obj[key];
    }
  }
  return obj;
}

function mat(str, pat) {
  return str.match(pat) ? str.match(pat)[1] : null;
}

export const DARKTHEME = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#dcdcdc",
    },
  },
});

export const THEME_BG_DULLER = "#383838";
export const THEME_BG = "#191919";
export const THEME_TEXT = "#f0f0f0";

const label = (str) => {
  const labels = LABELS["English"];
  return (labels && labels[str]) || str;
};
