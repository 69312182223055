import { useEffect, useState } from "react";
import { Cursor } from "./values/enums";
import { createMultiSelectBox, getUnmovingPoint } from "./utils/PointerUtils";
import { getTransformStr } from "./utils/transformUtils";

export function useScaler({ update }) {
  let [moving, setMoving] = useState(false);
  let [mousePos, setMousePos] = useState(null);
  let [unmovingPoint, setUnmovingPoint] = useState({ x: 0, y: 0 });
  let [initialItem, setInitialItem] = useState(null);
  let [cursor, setCursor] = useState(Cursor.Default);

  function onMouseDownNew({ xy, event, cursor: c, z }) {
    moving = true;
    setMoving(true);

    const item = z.items[z.selectedIds[0]];
    if (!item) return;

    const box = z.getBox(item.id);
    unmovingPoint = getUnmovingPoint(box, c);
    setUnmovingPoint(unmovingPoint);

    mousePos = xy;
    setMousePos(mousePos);

    cursor = c;
    setCursor(cursor);

    initialItem = JSON.parse(JSON.stringify(item));
    setInitialItem(initialItem);
  }

  function onMouseMoveNew({ xy, event, z }) {
    if (!moving || !mousePos) return;

    const item = z.items[z.selectedIds[0]];
    item.mpTransforms = item.mpTransforms || {};
    item.mpTransforms.translate = item.mpTransforms.translate || { x: 0, y: 0 };
    item.mpTransforms.scale = item.mpTransforms.scale || { x: 1, y: 1 };
    item.mpTransforms.rotate = item.mpTransforms.rotate || { angle: 0 };

    if (item.type == "mpimage") {
      if (z.croppingItemId == item.id) {
        const initialFrame = initialItem.customFrame || {
          innerRect: `<rect x="0" y="0" width="1" height="1"/>`,
          id: item.id + "-frame",
          scale: { x: 1, y: 1 },
        };
        const xScale = Math.abs(unmovingPoint.x - mousePos.x) / item.width;
        const yScale = Math.abs(unmovingPoint.y - mousePos.y) / item.height;
        item.customFrame.scale.x = xScale;
        item.customFrame.scale.y = yScale;
  
        // only scale transformation is supported for frames
        item.customFrame.transformStr = `scale(${item.customFrame.scale.x}, ${item.customFrame.scale.y})`;
      } else {
        modifyWidthHeight(item, cursor);
        modifyWidthHeight(item.imageRect, cursor);
      }
    } else if (item.type != "shape" && item.width && item.height) {
      modifyWidthHeight(item, cursor);
    } else {
      const xScale = Math.abs(unmovingPoint.x - mousePos.x) / item.width;
      const yScale = Math.abs(unmovingPoint.y - mousePos.y) / item.height;
      item.mpTransforms.scale.x = xScale;
      item.mpTransforms.scale.y = yScale;
    }

    item.mpTransformStr = getTransformStr(z.svgRef, item);
    z.items[item.id] = item;
    update({ items: z.items }, false);

    const box = createMultiSelectBox(z.items, z.selectedIds, z.getBox);
    z.multiSelectBox = box;
    z.setMultiSelectBox(box);

    mousePos = xy;
    setMousePos(mousePos);

    event.preventDefault();
    event.stopPropagation();
  }

  function modifyWidthHeight(item, cursor) {
    let xd = Math.abs(mousePos.x - unmovingPoint.x),
      yd = Math.abs(mousePos.y - unmovingPoint.y);
    yd = (xd * item.height) / item.width;

    const oldWidth = item.width;
    item.width = xd;
    if ([Cursor.ResizeSW, Cursor.ResizeW, Cursor.ResizeNW].includes(cursor)) {
      const widthChange = oldWidth - item.width;
      item.x += widthChange;
    }
    const oldHeight = item.height;
    item.height = yd;
    if ([Cursor.ResizeN, Cursor.ResizeNE, Cursor.ResizeNW].includes(cursor)) {
      item.y += oldHeight - item.height;
    }
  }

  function onMouseUpNew({ xy, event, z }) {
    if (!moving) return;
    if (!mousePos) return;

    moving = false;
    setMoving(false);

    mousePos = null;
    setMousePos(mousePos);

    unmovingPoint = null;
    setUnmovingPoint(unmovingPoint);

    // for undo/redo
    const { items, groups, defs, selectedIds } = z;
    update({ items, groups, defs, selectedIds }, true);

    // note: don't move these clearing-cursor and clearing-tool to onMouseLeave of the handles. it doesn't work,
    z.setTool(null);
    z.setCursor(Cursor.Default);
    z.setSelectedPoint(null);
  }

  function onMouseOutNew({ z }) {
    // if (!moving) {
    //   z.setTool(null);
    //   z.setCursor(Cursor.Default);
    // }
  }
  return {
    onMouseDownNew,
    onMouseMoveNew,
    onMouseOutNew,
    onMouseUpNew,
    name: "scaler",
  };
}
