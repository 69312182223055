import { useSVG } from "./SVGContext";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { createBlockServer, req } from "./utils/ServerUtils";
import { ROOT_GROUP_ID } from "./values/constants";
import YesIcon from "@mui/icons-material/Check";
import NoIcon from "@mui/icons-material/Close";
import SingleColIcon from "@mui/icons-material/Splitscreen";
import SingleRowIcon from "@mui/icons-material/ViewColumn";

function shouldExclude(id) {
  return id == "background";
}
export function AiTemplateCreator({}) {
  const { design, setDesign, userInfo, items, groups, update, getBox, setFullscreenLoading, notification, setNotification } = useSVG();

  function updateTemplateSpec(specs, vtype) {
    if (!design.templateId) {
      console.error("Design does not have a template ID");
      return;
    }
    // clear tags
    for (let id in items) {
      items[id].tag = null;
    }
    setFullscreenLoading(true);
    req(
      `/templates`,
      "POST",
      JSON.stringify({
        pages: design.pages,
        spec: specs,
        vtype: "verified-" + vtype,
        template_id: design.templateId,
      }),
      true,
    )
      .then((res) => {
        setFullscreenLoading(false);
        req(
          "/generatev2",
          "POST",
          JSON.stringify({
            url: "https://docs.google.com/document/d/e/2PACX-1vTdqo3bVRbXUd-B5EZendy7JRwUIoxwX2GRQ0V6HpO2ZfNRQ1wOzOI23It8EDF0ml7P4Fd6zieu0UZl/pub",
            template_id: design.templateId,
            dontReplace: true, // to generate sampler
          }),
        )
          .then((res) => {
            setFullscreenLoading(false);

            window.open("/design/" + res.design_id, "_blank");
          })
          .catch((e) => {
            setFullscreenLoading(false);
          });
      })
      .catch((e) => {
        setFullscreenLoading(false);
      });
  }
  function findGapsAndCreateRectangles() {
    // clear all tags
    for (let id in items) {
      items[id].tag = null;
    }
    let specs = [];

    let boxes = Object.keys(items)
      .map((id) => (shouldExclude(id) ? null : { ...items[id], ...getBox(id), id }))
      .filter((b) => b)
      .filter((box) => {
        if (box.height > design.height / 2 && box.width < 50) {
          // filter out vertical lines
          return false;
        }
        if (box.width > design.width * 0.7 && box.height > design.height * 0.5) {
          // filter out large boxes
          return false;
        }
        return true;
      });

    boxes = boxes.sort((a, b) => {
      if (Math.abs(a.y - b.y) < 5) return a.x - b.x;
      return a.y - b.y;
    });

    let fontGroups = {};
    for (let box of boxes) {
      if (box.proseMirrorData) {
        const f = extractFontSizeFromProseMirror(box.proseMirrorData);
        fontGroups[f] = fontGroups[f] || [];
        fontGroups[f].push(box);
      }
    }

    // split the boxes into groups of same-font-sizes
    let counts = [];
    for (let f in fontGroups) {
      counts.push(fontGroups[f].length);
    }

    // find smallest count > 2
    let blockCount = Math.min(...counts.filter((x) => x > 2));

    function recursivelyIdentifyMoreElements(box, seen) {
      let res = [box];
      seen[box.id] = true;
      for (let b in boxes) {
        if (!seen[box.id] && overlapPerc(b, box) > 0.3) {
          res = [...res, ...recursivelyIdentifyMoreElements(b, seen)];
        }
      }
      seen[box.id] = false;
      return res;
    }

    let blocks = [];
    for (let k = 0; k < blockCount; k++) {
      let block = [];
      for (let f in fontGroups) {
        if (fontGroups[f].length >= blockCount) {
          block.push(fontGroups[f][k]);

          block = [...block, ...recursivelyIdentifyMoreElements(fontGroups[f][k], {})];
        }
      }
      block.sort((a, b) => a.y - b.y);
      blocks.push(block);
    }
    let vtype = "",
      cols = 1;
    if (isSingleColumnLayout(blocks)) {
      vtype = "single-column";
      cols = 1;
      // here onwards the logic is specific to single-col layouts
      for (let i = 0; i < blocks.length; i++) {
        let block = blocks[i];
        let otherBlockItems = [];
        const blockMinY = Math.min(...block.map((b) => b.y));
        const blockMaxY = Math.max(...block.map((b) => b.y + b.height));

        const blockRect = {
          x: 0,
          y: blockMinY,
          width: design.width,
          height: blockMaxY - blockMinY,
        };

        // find all other items intersecting with this rectangle, and add them to the block
        otherBlockItems = boxes.filter((b) => {
          // if (overlapPerc(b, blockRect) > 0.3) return true;
          return false;
        });

        block = [...block, ...otherBlockItems];
        blocks[i] = block;
      }
    } else {
      vtype = "multi-column";
      console.log("Multi-column layout not supported yet");
      console.log("Is the block count", blockCount + " ?");

      for (let i = 1; i < blocks.length; i++) {
        if (blocks[i].x >= blocks[i - 1].x) continue;
        cols = 2;
        break;
      }
      console.log("Is the column count", cols + " ?");
    }
    for (let i = 0; i < blocks.length; i++) {
      const { block, ids } = markTags(blocks[i], i + 1, items, groups);
      blocks[i] = block;
      specs.push({
        type: "content block",
        items: ids,
      });
    }

    const minY = Math.min(...blocks.map((block) => Math.min(...block.map((b) => b.y))));
    const maxY = Math.max(...blocks.map((block) => Math.max(...block.map((b) => b.y + b.height))));
    // find all boxes with y < first block's minY
    const headerIds = boxes.filter((b) => b.y + b.height < minY).map((b) => b.id);
    let o = markTags(
      headerIds.map((id) => items[id]),
      "header",
      items,
      groups,
    );
    specs = {
      header: {
        items: o.ids,
      },
      blocks: specs,
    };

    // find all boxes with y > last block's maxY
    const footerIds = boxes.filter((b) => b.y > maxY).map((b) => b.id);

    o = markTags(
      footerIds.map((id) => items[id]),
      "footer",
      items,
      groups,
    );
    specs["footer"] = {
      items: o.ids,
    };

    // find all boxes that fall in between the blocks
    // for (let i = 0; i < blocks.length - 1; i++) {
    //   const block = blocks[i];
    //   const nextBlock = blocks[i + 1];
    //   const blockMaxY = Math.max(...block.map((b) => b.y + b.height));
    //   const nextBlockMinY = Math.min(...nextBlock.map((b) => b.y));
    //   const inBetweenIds = boxes.filter((b) => b.y > blockMaxY && b.y < nextBlockMinY).map((b) => b.id);
    //   o = markTags(
    //     inBetweenIds.map((id) => items[id]),
    //     "in-between-" + i,
    //     items,
    //   );
    //   specs["in-between-" + i] = {
    //     items: o.ids,
    //   };
    // }

    design.spec = specs;
    setDesign({ ...design });
    // todo: update this design

    // console.log("Spec: ", JSON.stringify(specs, null, 2));

    update({ items }); // to show the tags, for the user to confirm

    specs["cols"] = cols;

    function clearTags() {
      for (let id in items) {
        items[id].tag = null;
      }
      update({ items });
    }

    setNotification(
      <Notif
        yes={() => {
          // update template itself!

          updateTemplateSpec(specs, vtype);
          clearTags();
          setNotification(null);
        }}
        no={() => {
          clearTags();
          setNotification(null);
        }}
        vtype={vtype}
      />,
    );
    return;
  }

  return (
    userInfo?.permissions.includes("can_ai_generate") && (
      <Button onClick={() => findGapsAndCreateRectangles()} size="small" variant="contained" className="navbar-btn">
        Create AI Template
      </Button>
    )
  );
}

function enclosingBox(boxes) {
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;
  for (let box of boxes) {
    minX = Math.min(minX, box.x);
    minY = Math.min(minY, box.y);
    maxX = Math.max(maxX, box.x + box.width);
    maxY = Math.max(maxY, box.y + box.height);
  }
  return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
}

function extractTextFromProseMirror(node) {
  let text = "";

  if (node.type === "text") {
    text += node.text || "";
  }

  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((childNode) => {
      text += extractTextFromProseMirror(childNode);
    });
  }

  return text;
}
function extractFontSizeFromProseMirror(node) {
  let fontSize = 1;

  if (node.attrs?.fontSize) {
    fontSize = node.attrs.fontSize.replace("px", "");
  }
  for (let mark of node.marks || []) {
    if (mark.attrs?.fontSize) {
      fontSize = Math.max(fontSize, mark.attrs.fontSize.replace("px", ""));
    }
  }

  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((childNode) => {
      fontSize = Math.max(fontSize, extractFontSizeFromProseMirror(childNode));
    });
  }

  return fontSize;
}

function markTags(block, blockId, items, groups) {
  const clr = randomColor();
  block = block.map((b) => ({
    ...b,
    fontSize: b.proseMirrorData ? extractFontSizeFromProseMirror(b.proseMirrorData) : null,
    shapeSize: b.width * b.height,
  }));

  const textItems = block.filter((b) => b.proseMirrorData);
  const exclude = [];

  const positionId = getPositionItemId(getExcludedIds(textItems, exclude));
  if (positionId) {
    items[positionId].tag = blockId + ": position";
    exclude.push(positionId);
  }
  const biggestFontId = findTitleId(getExcludedIds(textItems, exclude));
  if (biggestFontId) {
    items[biggestFontId].tagType = "title";
    items[biggestFontId].tag = blockId + ": title";
    exclude.push(biggestFontId);
  }

  let id = findLongestFontId(getExcludedIds(textItems, exclude));
  if (id) {
    items[id].tagType = "body";
    items[id].tag = blockId + ": body";
    exclude.push(id);
  }

  id = findLongestFontId(getExcludedIds(textItems, exclude));
  if (id) {
    items[id].tagType = "subtitle";
    items[id].tag = blockId + ": subtitle";
    exclude.push(id);
  }

  const vectors = block.filter((b) => b.type == "vector" || b.svg);
  if (vectors[0]) {
    id = vectors[0].id;
    items[id].tagType = "vector";
    items[id].tag = blockId + ": vector";
    exclude.push(id);
  }

  const images = block.filter((b) => b.imageRect);
  if (images[0]) {
    id = images[0].id;
    items[id].tagType = "image";
    items[id].tag = blockId + ": image";
    exclude.push(id);
  }

  const ids = {};

  // mark all other items of this block as belonging to it
  for (let item of block) {
    items[item.id].tagColor = clr;

    items[item.id].tag = blockId + "";
    const val = { id: item.id };
    if (items[item.id].tagType) {
      val.type = items[item.id].tagType;
    }
    ids[item.id] = val;
  }
  const sortedItems = [];
  let order = 0;
  groups[ROOT_GROUP_ID].children.forEach((id, i) => {
    if (ids[id]) {
      sortedItems.push(ids[id]);
      items[id].orderTag = order;
      order += 1;
    }
  });

  return { block, ids: sortedItems };
}

function getExcludedIds(block, exclude) {
  return block.filter((b) => !exclude.includes(b.id));
}

function getPositionItemId(block, exclude) {
  return block.filter((b) => {
    const text = extractTextFromProseMirror(b.proseMirrorData);
    //   if it has a number, it's a position
    if (text.length < 3 && text.match(/\d+/)) return true;
    return false;
  })[0]?.id;
}

function findTitleId(block, exclude) {
  // biggest font with length > 5
  let biggestFont = 0,
    biggestFontId = null;
  for (let item of block) {
    if (item.fontSize > biggestFont && extractTextFromProseMirror(item.proseMirrorData).length > 5) {
      biggestFont = item.fontSize;
      biggestFontId = item.id;
    }
  }
  return biggestFontId;
}
function findLongestFontId(block, exclude) {
  let longestText = 0,
    longestTextId = null;
  for (let item of block) {
    const text = extractTextFromProseMirror(item.proseMirrorData);
    if (text.length > longestText) {
      longestText = text.length;
      longestTextId = item.id;
    }
  }
  return longestTextId;
}

function Notif({ yes, no, vtype }) {
  console.log("vtype", vtype);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",

        justifyContent: "start",
        alignItems: "center",
        gap: 20,
      }}
    >
      <ButtonGroup
        style={{
          border: "1px solid #ccc",
        }}
      >
        <SingleColIcon
          style={{
            color: "#333",
            backgroundColor: vtype == "single-column" ? "#ccc" : "transparent",
          }}
        />
        <SingleRowIcon
          style={{
            color: "#333",
            backgroundColor: vtype == "single-row" ? "#ccc" : "transparent",
          }}
        />
      </ButtonGroup>
      Does this look ok?{" "}
      <IconButton size="small" color="success" onClick={yes}>
        <YesIcon />
      </IconButton>
      <IconButton size="small" color="error" onClick={no}>
        <NoIcon />
      </IconButton>
    </div>
  );
}

function overlapPerc(a, b) {
  // find what % of rect a is covered by rect b
  const x_overlap = Math.max(0, Math.min(a.x + a.width, b.x + b.width) - Math.max(a.x, b.x));
  const y_overlap = Math.max(0, Math.min(a.y + a.height, b.y + b.height) - Math.max(a.y, b.y));
  const overlapArea = x_overlap * y_overlap;
  const aArea = a.width * a.height;
  return overlapArea / aArea;
}
function randomColor() {
  // use a random hue and fixed saturation and lightness
  const hue = Math.floor(Math.random() * 360);
  const saturation = 80;
  const lightness = 50;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

function isSingleColumnLayout(blocks) {
  // if the minY value of each block increases, it's a single column layout
  let y = -Infinity;
  for (let block of blocks) {
    const blockMinY = Math.min(...block.map((b) => b.y));
    if (blockMinY <= y) return false;
    y = Math.max(...block.map((b) => b.y + b.height));
  }
  return true;
}
