import React, { memo, useEffect, useRef, useState } from "react";
import { TextField, IconButton, Autocomplete, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LazyImage from "./LazyImage";
import { TEMPLATE_CATEGORIES } from "../values/TemplateCategories";
import { fetchTemplates, searchTemplates } from "../utils/ServerUtils";
import { CircularProgress } from "@mui/material";
import { cap, getTemplatePreviewUrl } from "../utils/utils";
import { SUBCATEGORIES } from "../values/Subcategories";
import { AddTemplateBtn } from "../AddtemplateBtn";
import { LazyImageWithAddBtn } from "./LazyImageWithAddBtn";
import Gallery from "../Gallery";
import { useSVG } from "../SVGContext";

const TemplateSearch = memo(({ onTemplateSelect }) => {
  let [category, setCategory] = useState("infographics");
  const [query, setQuery] = useState("");
  let [results, setResults] = useState([]);
  const [cache, setCache] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const containerRef = useRef();
  let [loading, setLoading] = useState(false);
  const { isMobile } = useSVG();

  useEffect(() => {
    setQuery(category);
    fetchMoreTemplates();
  }, []);

  const fetchMoreTemplates = async () => {
    if (loading || currentPage > totalPages) return;
    loading = true;
    setLoading(true);
    if (category) {
      const res = await fetchTemplates(category, currentPage);
      setCurrentPage(res.current_page + 1);
      setTotalPages(res.total_pages);
      setPerPageCount(res.per_page);

      let prev = cache[category] || {};
      prev.templates = prev.templates || [];
      prev.templates = [...prev.templates, ...res.templates];
      prev.current_page = res.current_page;
      setCache({ ...cache, [category]: prev });
      setResults([...prev.templates]);
      setLoading(false);
    } else if (query) {
      searchTemplates(query, "", currentPage).then((res) => {
        setResults([...results, ...res.templates]);
        setCurrentPage(res.current_page + 1);
        setTotalPages(res.total_pages);
        setPerPageCount(res.per_page);

        setLoading(false);
      });
    }
  };
  const handleScroll = () => {
    const container = containerRef.current;
    if (container.scrollHeight - container.scrollTop <= container.clientHeight * 1.1) {
      fetchMoreTemplates();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  const onEnter = (event) => {
    setCurrentPage(1);
    setTotalPages(1);
    results = [];
    setResults([]);
    setCategory(null);

    setTimeout(() => {
      fetchMoreTemplates();
    }, 0);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  let toShow = results.length > 0 ? results : cache[category]?.templates ? cache[category]?.templates : [];
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", padding: "0 20px" }}>
        <Autocomplete
          fullWidth
          freeSolo
          options={TEMPLATE_CATEGORIES}
          inputValue={query}
          onInputChange={(event, newValue) => {
            if (TEMPLATE_CATEGORIES.includes(newValue)) {
              category = newValue;
              setCategory(category);

              if (cache[category]) {
                setResults([...cache[category].templates]);
                setCurrentPage(cache[category].current_page + 1);
              } else {
                setCurrentPage(1);
                fetchMoreTemplates();
              }
            } else {
              setCategory(null);
              setQuery(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" onKeyPress={handleKeyPress} placeholder="Search Templates" />}
        />

        <IconButton onClick={onEnter}>
          <SearchIcon />
        </IconButton>
      </div>

      <div
        ref={containerRef}
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Gallery
          cols={2}
          style={{
            // overflowY: "auto",
            width: "100%",
            maxWidth: "100%",
          }}
          data={toShow}
          render={(item, itemWidth) => <TemplateSearchResult item={item} itemWidth={itemWidth} onTemplateSelect={onTemplateSelect} />}
        />
        {loading && (
          <div style={{ textAlign: "left", padding: "10px" }}>
            <CircularProgress />
          </div>
        )}
        {!loading && currentPage < totalPages && (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Button onClick={fetchMoreTemplates}>Load More</Button>
          </div>
        )}
      </div>
    </>
  );
});
export default TemplateSearch;

function TemplateSearchResult({ item, itemWidth, onTemplateSelect }) {
  let w, h;
  const ar = item.width / item.height;
  const itemHeight = itemWidth / ar;
  w = itemWidth;
  h = itemHeight;

  return <LazyImageWithAddBtn key={item.id} src={getTemplatePreviewUrl(item.id)} alt={item.title} width={w} height={h} onClick={() => onTemplateSelect(item.id)} addString={"Add to Design"} />;
}
