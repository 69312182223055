// @ts-nocheck
import { Box, ClickAwayListener, IconButton, Menu, MenuItem, Popper, Slider, TextField, Tooltip } from "@mui/material";
import { useSVG } from "../SVGContext";
import { transform } from "../utils/transformUtils";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import { RotateBtn } from "./RotateBtn";
import { TopbarCommons, TopBarNumber } from "./TopbarCommons";
import { RoundCorners } from "../views/RoundCorners";
import { changeColors, changeHues, FillColor } from "./TopbarMain";
import { getHue } from "./colors";

export const TopbarShape = ({ item }) => {
  useEffect(() => {}, [item]);

  if (!item) return;
  let { items, svgRef, update } = useSVG();

  let props = [
    { name: "width", min: 1, max: 500, step: 1 },
    { name: "height", min: 1, max: 500, step: 1 },
    { name: "angle", min: 0, max: 360, step: 1 },
  ];
  if (item.sides) {
    props.push({ name: "sides", min: 3, max: 20, step: 1 });
  }
  if (item.innerRadius) {
    props.push({ name: "innerRadius", min: 1, max: 500, step: 1 });
    props.push({ name: "outerRadius", min: 1, max: 500, step: 1 });
  }
  props = props.filter((prop) => item[prop.name] !== undefined);

  const getValue = (item, prop) => {
    if (prop.name == "angle") {
      let angle = item.rotation ? item.rotation.angle : 0;
      return Math.round(toDegree(angle));
    }
    return item[prop.name];
  };
  return (
    <>
      <StrokeColor item={item} isStroke={false} />
      <StrokeColor item={item} isStroke={true} />
      <TopBarNumber name="Stroke Width" icon={<ShowChartIcon />} valueName="strokeWidth" min={0} max={20} step={0.5} defaultValue={0} />
      <Dash item={item} />

      {props.map((prop, pidx) => (
        <span key={pidx}>
          <TextField
            label={prop.name[0].toUpperCase() + prop.name.substr(1)}
            type="number"
            size="small"
            style={{ width: "100px" }}
            defaultValue={getValue(item, prop)}
            onChange={(e) => {
              let t = {};
              if (prop.name == "angle") {
                t.type = "rotate";
                t.angle = toRadians(parseFloat(e.target.value));
              } else if (prop.name == "width") {
                t.type = "scale";
                t.x = parseFloat(e.target.value) / item.width;
                t.y = 1;
              } else if (prop.name == "height") {
                t.type = "scale";
                t.x = 1;
                t.y = parseFloat(e.target.value) / item.height;
              } else if (["sides", "innerRadius", "outerRadius"].includes(prop.name)) {
                // TODO: fixme
                item[prop.name] = parseInt(e.target.value);
                item = initSegments(initPointsForShape(item));
              }
              if (t.type) {
                t.center = item.center;
                item = transform(item, t);
              }
              items[item.id] = item;
              update({ items });
            }}
          />
        </span>
      ))}

      <RoundCorners item={item} />
    </>
  );
};
function StrokeColor({ isStroke }) {
  const { items, update, selectedIds } = useSVG();
  const clr = isStroke ? items[selectedIds[0]]?.stroke : items[selectedIds[0]]?.fill;

  return (
    <ColorSquare
      isStroke={isStroke}
      color={clr}
      onChange={(color, opts) => {
        if (opts?.rotateAll) {
          const srcColor = items[selectedIds[0]].fill; // or font color

          const hue1 = getHue(srcColor);
          const hue2 = getHue(color.hex);
          const hueDelta = hue2 - hue1;
          changeHues(hueDelta, items, update);
          return;
        }
        if (opts?.changeAll) {
          const srcColor = items[selectedIds[0]].fill; // or font color
          changeColors(items, update, {
            [srcColor]: color.hex,
            transparent: "transparent",
          });
          return;
        }
        for (let id of selectedIds) {
          let item = items[id];
          if (isStroke) {
            item.stroke = color.hex;
          } else {
            item.fill = color.hex;
          }
          items[item.id] = item;
        }
        update({ items });
      }}
      allowChangeAll={!isStroke}
      allowRotateAll={!isStroke}
    />
  );
}

const popperStyle = {
  backgroundColor: "white",
  border: "solid 1x gray",
};
const options = [[], [5], [2], [5, 10]];

function Line({ dashArray }) {
  return (
    <svg width="40px" height="30" className="dasharray-svg" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <line x1="0" y1="5" x2="100" y2="5" stroke="black" stroke-width="2" stroke-dasharray={dashArray.join(", ")} />
    </svg>
  );
}
function DashPopper({ anchorEl, onClose, onClick }) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} style={popperStyle}>
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
          {options.map((option, index) => {
            return (
              <div key={index} onClick={() => onClick(index)}>
                <Line dashArray={option} />
              </div>
            );
          })}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
export function Dash({ item }) {
  const { items, update } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);

  function onClick(index) {
    // this is to make the dash-change obvious to the user
    item.strokeWidth = item.strokeWidth || 2;
    item.stroke = item.stroke || "black";

    items[item.id].strokeDasharray = options[index].join(", ");
    update({ items });

    setAnchorEl(null);
  }

  const currentValue = item.strokeDasharray?.split(", ") || [];
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "start", alignItems: "start" }}>
        <IconButton
          ref={ref}
          type="button"
          value="Dashed Line"
          onClick={() => {
            setAnchorEl(anchorEl ? null : ref.current);
          }}
        >
          <Tooltip title="Dash">
            <Line dashArray={currentValue} />
          </Tooltip>
        </IconButton>
      </div>
      {anchorEl && <DashPopper anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onClick={onClick} />}
    </div>
  );
}
